import React from "react";
import { Card, CardHeader, CardBody, CardTitle } from "reactstrap";
import CurrencyFormatter from "currency-formatter";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const Skel = () => (
  <SkeletonTheme color="#272A3C" highlightColor="#46495B">
    <Skeleton width={100} height={60} />
  </SkeletonTheme>
)

const CardUnit = ({ aluguel, loading, height }) => {
  const value = CurrencyFormatter.format(aluguel, { code: "BRL" });
  // const windowSize = useWindowSize();
  // const [fontSize, setFontSize] = useState(60);

  // useEffect(() => {
  //   if (aluguel) {
  //     const width = cardEl.current.offsetWidth;
  //     const fontSize = width / ((value.length / 3) * 2);

  //     setFontSize(fontSize);
  //   }
  // }, [windowSize, aluguel]);

  return (
    <Card className="card-chart" style={{height}}>
      <CardHeader>
        <CardTitle
          tag="div"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <span style={{ fontSize: 24 }}>Total</span>
        </CardTitle>
      </CardHeader>
      <CardBody>
        <div
          className="chart-area"
          style={{
            display: "flex",
            justifyContent: "flex-end",
            height: "fit-content"
          }}
        >
          <span
            style={{
              fontSize: 36,
              textAlign: "right",
              color: "#f5f7fa",
              lineHeight: 1
            }}
          >
            {loading ? <Skel /> : value}
          </span>
        </div>
      </CardBody>
    </Card>
  );
};

export default CardUnit;

import React from "react";
import {
  Card,
  CardBody,
  Badge
} from "reactstrap";
import Loading from "../../components/Loading";
import {
  Grid,
  Table,
  TableHeaderRow
} from "@devexpress/dx-react-grid-bootstrap4";

const CardTable = props => {
  return (
    <Card>
      <CardBody>
        {
          props.loading ? 
            <Loading />
          : (
            <Grid rows={props.data} columns={[
              { title: "Empreendimento", name: "HOTEL" },
              { title: "Unidades", name: "UNIDADES" },
              { title: "Ação", name: "action", getCellValue: item => (
                <Badge
                  color="info"
                  style={{ fontWeight: "normal", cursor: "pointer" }}
                  onClick={() => {
                    props.navigation.push({
                      pathname: "/admin/hotel/" + item.IDHOTEL,
                      state: {
                        item
                      }
                    });
                  }}
                >
                  Ver mais
                </Badge>
              ) },
            ]}>
              <Table 
                columnExtensions={[
                  { columnName: 'HOTEL', align: 'left' },
                  { columnName: 'UNIDADES', align: 'right' },
                  { columnName: 'action', align: 'center' }
                ]}
              />
              <TableHeaderRow />
            </Grid>
        )
      }
      </CardBody>
    </Card>
  );
};

export default CardTable;

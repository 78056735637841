import { format } from "currency-formatter";

let chartOptions = type => ({
  maintainAspectRatio: false,
  legend: {
    display: false
  },
  tooltips: {
    backgroundColor: "#f5f5f5",
    titleFontColor: "#333",
    bodyFontColor: "#666",
    bodySpacing: 4,
    xPadding: 12,
    mode: "nearest",
    intersect: 0,
    position: "nearest",
    callbacks: {
        label: function(tooltipItems, data) { 
          if (type === "%") {
            return tooltipItems.yLabel + "%";
          }

          return format(tooltipItems.yLabel, {code: 'BRL'});
        }
    }
  },
  responsive: true,
  scales: {
    yAxes: [
      {
        barPercentage: 1.6,
        gridLines: {
          drawBorder: false,
          color: "rgba(29,140,248,0.0)",
          zeroLineColor: "transparent"
        },
        ticks: {
          suggestedMin: 0,
          suggestedMax: 100,
          padding: 20,
          fontColor: "#FFF",
          callback: value => {
            if (type === "%") {
              return value + "%";
            }
  
            return format(value, {code: 'BRL'});
          }
        }
      }
    ],
    xAxes: [
      {
        barPercentage: 1.6,
        gridLines: {
          drawBorder: false,
          color: "rgba(29,140,248,0.1)",
          zeroLineColor: "transparent"
        },
        ticks: {
          padding: 20,
          fontColor: "#FFF"
        }
      }
    ]
  }
});

export default chartOptions;

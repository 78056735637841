import { createStore } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import Reducers from "../reducers";

const persistConfig = {
    key: "atrio" /* key é obrigatorio. */,
    storage /* storage é obrigatorio. */,
    blacklist: [] /* Lista para não persistir. */
};

const persistedReducer = persistReducer(persistConfig, Reducers);
/* eslint-disable no-underscore-dangle */
const store = createStore(persistedReducer);
/* eslint-enable */
const persistor = persistStore(store);

export { store, persistor };
import React, { useRef } from "react";
import { Card, CardHeader, CardBody, CardTitle } from "reactstrap";
import CurrencyFormatter from "currency-formatter";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const Skel = () => (
  <SkeletonTheme color="#272A3C" highlightColor="#46495B">
    <Skeleton width={100} height={60} />
  </SkeletonTheme>
)

const CardIncome = props => {
  const income = CurrencyFormatter.format(props.income, { code: "BRL" });
  const cardEl = useRef(null);

  return (
    <Card style={{height: props.height}}>
      <CardHeader>
        <CardTitle
          tag="div"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <span style={{ fontSize: 24, color: "#00FFC8" }}>Renda líquida</span>
        </CardTitle>
      </CardHeader>
      <CardBody>
        <div
          className="chart-area"
          style={{
            display: "flex",
            justifyContent: "flex-end",
            height: "fit-content"
          }}
          ref={cardEl}
        >
          <span
            style={{
              fontSize: 36,
              textAlign: "right",
              color: "#f5f7fa",
              lineHeight: 1
            }}
          >
            {props.loading ? <Skel /> : income}
          </span>
        </div>
      </CardBody>
    </Card>
  );
};

export default CardIncome;

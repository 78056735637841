import React, { useEffect, useState } from 'react';
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

import Api from 'api';
import { connect } from 'react-redux';

import { showMessage } from "../../actions";

const UnitInfo = ({numdoc, showMessage}) => {
    const [value, setValue] = useState("");
    const [loading, setLoading] = useState(false);
    
    useEffect(() => {
        setLoading(true);
        Api.get("investidor/app/total-unidades", {
            params: {
                NUMDOCUMENTO: numdoc
            }
        }).then(({data}) => {
            if (data.status === "error") {
                showMessage({message: "Erro não esperado", open: true});
                setLoading(false);
            } else {
                setValue(data.data.total);
                setLoading(false);
            }
        }).catch(e => {
            console.log(e);
            setLoading(false);
        })
    }, []);

    if (loading) {
        return <SkeletonTheme color="#272A3C" highlightColor="#46495B">
            <Skeleton width={100} height={20} />
        </SkeletonTheme>
    }

    return <span style={{ color: "#d2d2d5" }}>{value || 0} Unidades</span>
};


export default connect(null, { showMessage })(UnitInfo);

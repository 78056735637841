const gradientBlack = ["#272A3C", "#232530"];
const gradientRed = ["#C94136", "#772764"];

const initialState = {
  name: "",
  username: "",
  sidebarColor: gradientRed
};

const defaultAction = {
  type: "",
  payload: initialState
};

const switchColor = (color = []) => {
  if (color[0] === gradientBlack[0]) {
    return gradientRed;
  }
  return gradientBlack;
};

export default (state = initialState, action = defaultAction) => {
  switch (action.type) {
    case "LOGIN":
      return action.payload;
    case "LOGOUT":
      return initialState;
    case "CHANGE_DATA":
      return {...state, ...action.payload};
    case "CHANGE_SIDEBAR":
      return { ...state, sidebarColor: switchColor(state.sidebarColor) };
    default:
      return state;
  }
};

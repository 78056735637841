import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col, CardHeader, CardTitle } from "reactstrap";

import Api from "../../api";

import CardTable from "./CardTable";

import { showMessage } from "../../actions";

class HotelList extends Component {
  state = {
    hotelList: [],
    loading: true
  }

  componentDidMount() {
    this.loadHotels()
  }

  loadHotels = async () => {
    this.setState({loading: true});
    try {
      const {data} = await Api.get("investidor/users/hoteis", {
        params: {
          NUMDOCUMENTO: this.props.login
        }
      });

      if (data.status === "error") {
        this.props.showMessage({message: data.data, open: true});
        this.setState({loading: false});
        return false;
      }

      this.setState({hotelList: data.data, loading: false});
    } catch(e) {
      this.setState({loading: false});
      this.props.showMessage({message: "Erro não esperado", open: true});
      console.log(e)
    }
  }

  render() {
    const {hotelList, loading} = this.state;

    return (
      <div className="content">
        <Row>
          <Col lg="12">
            <CardHeader
              style={{ backgroundColor: 'inherit', paddingBottom: 0 }}
            >
              <CardTitle
                tag="h3"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  height: "100%"
                }}
              >
                Hoteis
              </CardTitle>
            </CardHeader>
            <CardTable
              data={hotelList}
              navigation={this.props.history}
              loading={loading}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = ({user}) => ({login: user.login});

export default connect(mapStateToProps, { showMessage })(HotelList);

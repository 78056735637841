import React from "react";
import { connect } from "react-redux";

import { login, showMessage } from "actions";
import {
  Row,
  Col,
  Input,
  Button
} from "reactstrap";
import { useState } from "react";
import Api from "../../api";

const ForgotPassword = ({ login, showMessage, history }) => {
    const [cpf, setCpf] = useState("");
    const [password, setPassword] = useState("");
    const [code, setCode] = useState("");
    const [confirm, setConfirm] = useState(false);
    const [message, setMessage] = useState("");

    const handleSend = async () => {
        try {
            if (!cpf) {
                showMessage({message: "Você deve preencher o seu CPF/CNPJ", open: true});
                return false;
            }
            const { data } = await Api.post("investidor/auth/recover", {
                login: cpf
            });

            setConfirm(true);
            setMessage(data.data)
        } catch (e) {
            console.log(e)
        }
    };

    const handleConfirm = async () => {
        try {
            if (!password) {
                showMessage({message: "Preencha a nova senha para redefini-la", open: true});
                return false;
            }

            await Api.post("investidor/auth/recovery", {
                password,
                hash: code
            });

            history.goBack();
        } catch (e) {
            console.log(e)
        }
    };

    return (
        <div
            className="content"
            style={{
                height: "100%",
                display: "flex",
                justifyContent: "space-evenly",
                flexDirection: "column",
                backgroundImage: `url(${require("../../assets/img/bg_login.png")})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                overflow: "hidden",
                zIndex: -2
            }}
        >
            <div style={{
                display: "flex",
                justifyContent: "center"
            }}>
                <img src={require("../../assets/img/img_logo.png")} alt="" />
            </div>
            <Row
                style={{ justifyContent: "center", alignItems: "center", zIndex: 2 }}
            >
                { 
                    confirm  ? (
                        <Col lg="3">
                            <h4 style={{textAlign: 'center'}}>
                                {message}
                            </h4>
                            <Input
                                value={code}
                                onChange={e => setCode(e.target.value)}
                                style={{ backgroundColor: "#000", marginBottom: 10 }}
                                placeholder="Código"
                            />
                            {
                                code.length === 6 && (
                                    <Input 
                                        value={password}
                                        onChange={e => setPassword(e.target.value)}
                                        style={{ backgroundColor: "#000" }}
                                        placeholder="Nova senha"
                                    />
                                )
                            }
                            <Button color="info" style={{ width: "100%" }} onClick={handleConfirm}>ENVIAR</Button>
                        </Col>
                    ) : ( 
                        <Col lg="3">
                            <h4 style={{textAlign: 'center'}}>
                                Informe seu CPF ou CNPJ
                            </h4>
                            <Input
                                value={cpf}
                                onChange={e => setCpf(e.target.value)}
                                style={{ backgroundColor: "#000" }}
                                placeholder="CPF/CNPJ"
                            />
                            <Button color="info" style={{ width: "100%" }} onClick={handleSend}>ENVIAR</Button>
                        </Col>
                    )
                }
            </Row>
        </div>
    );
};

const mapStateToProps = state => ({});

export default connect(mapStateToProps, { login, showMessage })(ForgotPassword);

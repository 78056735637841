export const login = payload => ({
  type: "LOGIN",
  payload
});

export const logout = () => ({
  type: "LOGOUT"
});

export const changeData = data => ({
  type: "CHANGE_DATA",
  payload: data
})

export const changeSidebarColor = () => ({
  type: "CHANGE_SIDEBAR"
});

export const showMessage = data => ({
  type: "SHOW_MESSAGE",
  payload: data
})

export const hideMessage = () => ({
  type: "HIDE_MESSAGE"
})
import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col, Card, CardBody, Input, Button } from "reactstrap";
import { changeSidebarColor, changeData, showMessage } from "../../actions";

import Api from "../../api";

class Profile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: props.user.name,
      username: props.user.login,
      changePassword: false,
      actualPassword: "",
      newPassword: "",
      confirmNewPassword: ""
    };
  }

  handleSave = async () => {
    try {
      const {name, username, changePassword, newPassword, confirmNewPassword} = this.state;
      
      if (changePassword && newPassword !== confirmNewPassword) {
        this.props.showMessage({message: "As senhas não estão iguais, verifique a sua nova senha", open: true});
        return false;
      }

      let newProfile = {
        name,
        login: username,
      }

      if (changePassword) {
        newProfile.password = newPassword
      }

      const {data} = await Api.put("investidor/users/update", newProfile);
      
      if (data.status === "error" || !data.data) {
        throw new Error();
      }

      changeData(newProfile);

      this.props.history.push("/admin/dashboard");
    } catch(e) {
      console.log(e);
      this.props.showMessage({message: "Algo aconteceu de errado, tente novamente mais tarde", open: true});
    }
  }

  renderFields = () => {
    const { name, username, changePassword } = this.state;

    return (
      <>
        <div>
          <h5 style={{ marginBottom: 0 }}>Nome</h5>
          <Input
            value={name}
            onChange={e => ""}
            style={{ backgroundColor: "#000", marginBottom: 10 }}
          />
        </div>

        <div>
          <h5 style={{ marginBottom: 0 }}>Username</h5>
          <Input
            value={username}
            onChange={e => ""}
            style={{ backgroundColor: "#000", marginBottom: 10 }}
          />
        </div>
        <Button onClick={this.props.changeSidebarColor}>
          <span>Alterar cor</span>
        </Button>

        <div style={{ display: "flex" }}>
          <Input
            type="checkbox"
            style={{
              backgroundColor: "#000",
              marginBottom: 10,
              position: "relative",
              marginLeft: 0
            }}
            onChange={() => this.setState({ changePassword: !changePassword })}
          />
          <h5 style={{ marginBottom: 0 }}>Trocar senha</h5>
        </div>
      </>
    );
  };

  renderPasswordFields = () => {
    const { actualPassword, newPassword, confirmNewPassword } = this.state;

    return (
      <Row>
        <Col lg="12">
          <div>
            <h5 style={{ marginBottom: 0 }}>Senha atual</h5>
            <Input
              value={actualPassword}
              onChange={e => this.setState({ actualPassword: e.target.value })}
              style={{ backgroundColor: "#000", marginBottom: 10 }}
            />
          </div>
        </Col>
        <Col lg="6">
          <div>
            <h5 style={{ marginBottom: 0 }}>Nova senha</h5>
            <Input
              value={newPassword}
              onChange={e => this.setState({ newPassword: e.target.value })}
              style={{ backgroundColor: "#000", marginBottom: 10 }}
            />
          </div>
        </Col>
        <Col lg="6">
          <div>
            <h5 style={{ marginBottom: 0 }}>Confirmar nova senha</h5>
            <Input
              value={confirmNewPassword}
              onChange={e =>
                this.setState({ confirmNewPassword: e.target.value })
              }
              style={{ backgroundColor: "#000", marginBottom: 10 }}
            />
          </div>
        </Col>
      </Row>
    );
  };

  render() {
    const { changePassword } = this.state;

    return (
      <div className="content">
        <Row style={{ justifyContent: "center" }}>
          <Col lg="6" md="6" sm="8" xs="10">
            <Card>
              <CardBody>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <h1>Conta</h1>
                </div>
                {this.renderFields()}
                {changePassword && this.renderPasswordFields()}

                <Row>
                  <Col
                    lg="12"
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <Button
                      color="info"
                      style={{ width: "100%" }}
                      onClick={this.handleSave}
                    >
                      Enviar
                    </Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = ({ user }) => ({ user });

export default connect(mapStateToProps, { changeSidebarColor, showMessage })(Profile);

/*!

=========================================================
* Black Dashboard React v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";
import { connect } from "react-redux";
import { NavLink, Link } from "react-router-dom";
// nodejs library to set properties for components
import { PropTypes } from "prop-types";

// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";

// reactstrap components
import { Nav, Button } from "reactstrap";

import Api from "../../api";

var ps;

const gradientBlack = ["#272A3C", "#232530"];

const gradientRed = ["#C94136", "#772764"];

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.activeRoute.bind(this);
  }

  state = {
    colors: gradientRed,
    angle: "180deg",
    qtdNotify: 0
  };

  // verifies if routeName is the one active (in browser input)
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  }
  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.refs.sidebar, {
        suppressScrollX: true,
        suppressScrollY: false
      });
    }

    Api.get("investidor/notifications/total").then(({data}) => {
      if (data.data) {
        return false;
      }

      this.setState({
        qtdNotify: data.data.total
      })
    });
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
  }
  linkOnClick = () => {
    document.documentElement.classList.remove("nav-open");
  };
  render() {
    const { bgColor, routes, logo } = this.props;
    const { qtdNotify } = this.state;
    let logoImg = null;
    let logoText = null;
    let angle = "180deg";

    if (bgColor[0] === "#272A3C") {
      angle = "270deg";
    }

    if (logo !== undefined) {
      if (logo.outterLink !== undefined) {
        logoImg = (
          <a
            href={logo.outterLink}
            className="simple-text logo-mini"
            target="_blank"
            onClick={this.props.toggleSidebar}
          >
            <div className="logo-img">
              <img src={logo.imgSrc} alt="react-logo" />
            </div>
          </a>
        );
        logoText = (
          <a
            href={logo.outterLink}
            className="simple-text logo-normal"
            target="_blank"
            onClick={this.props.toggleSidebar}
          >
            {logo.text}
          </a>
        );
      } else {
        logoImg = (
          <Link
            to={logo.innerLink}
            className="simple-text logo-mini"
            onClick={this.props.toggleSidebar}
          >
            <div className="logo-img">
              <img src={logo.imgSrc} alt="react-logo" />
            </div>
          </Link>
        );
        logoText = (
          <Link
            to={logo.innerLink}
            className="simple-text logo-normal"
            onClick={this.props.toggleSidebar}
          >
            {logo.text}
          </Link>
        );
      }
    }
    return (
      <div
        className="sidebar"
        style={{
          background: `linear-gradient(${angle}, ${bgColor[0]}, ${bgColor[1]})`,
          width: 180
        }}
      >
        <div
          className="sidebar-wrapper"
          ref="sidebar"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between"
          }}
        >
          <Nav>
            {routes.map((prop, key) => {
              if (prop.redirect || !prop.name) return null;
              // Verifica se o link é externo
              if (prop.external) {
                return (
                  <li
                    className={this.activeRoute(prop.path) + (prop.pro ? " active-pro" : "")}
                    key={key}
                  >
                    <a
                      href={prop.path}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="nav-link sidebar-nav-link"
                      onClick={this.props.toggleSidebar}
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div>
                          {prop.icon}
                          {
                          prop.path === "/notification" && qtdNotify > 0 && (
                            <div style={{ borderRadius: 10, width: 10, height: 10, backgroundColor: "#0F0", position: 'absolute', bottom: 10, left: 5 }} />
                            )
                          }
                        </div>
                        <h5 style={{ margin: 0 }}>
                          {prop.name}
                        </h5>
                      </div>
                    </a>
                  </li>
                );
              }
              return (
                <li
                  className={
                    this.activeRoute(prop.path) +
                    (prop.pro ? " active-pro" : "")
                  }
                  key={key}
                >
                  <NavLink
                    to={prop.layout + prop.path}
                    className="nav-link sidebar-nav-link"
                    activeClassName="active"
                    onClick={this.props.toggleSidebar}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div>
                        {prop.icon}
                        {
                        prop.path === "/notification" && qtdNotify > 0 && (
                          <div style={{borderRadius: 10, width: 10, height: 10, backgroundColor: "#0F0", position: 'absolute', bottom: 10, left: 5}} />
                          )
                        }
                      </div>
                      <h5 style={{ margin: 0 }}>
                        {prop.name}
                      </h5>
                    </div>
                  </NavLink>
                </li>
              );
            })}
          </Nav>
        </div>
      </div>
    );
  }
}

Sidebar.defaultProps = {
  rtlActive: false,
  bgColor: gradientRed,
  routes: [{}]
};

Sidebar.propTypes = {
  // if true, then instead of the routes[i].name, routes[i].rtlName will be rendered
  // insde the links of this component
  rtlActive: PropTypes.bool,
  bgColor: PropTypes.oneOf(["primary", "blue", "green"]),
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the text of the logo
    text: PropTypes.node,
    // the image src of the logo
    imgSrc: PropTypes.string
  })
};

const mapStateToProps = ({ user }) => ({ bgColor: user.sidebarColor });

export default connect(mapStateToProps)(Sidebar);

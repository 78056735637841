import React from 'react';
import { FaArrowLeft } from 'react-icons/fa';

const Terms = ({location, history}) => {
    const isMobile = !!location.search.match(/mobile/g)

    return (
        <div style={{
            padding: 20,
        }}>
            {
                !isMobile && (
                    <div 
                        style={{display: 'flex'}}
                        onClick={() => {
                            history.push("/admin/dashboard")
                        }}
                    >
                        <h4 
                            style={{
                                fontSize: 20
                            }}
                        >
                            <FaArrowLeft
                                size={20}
                                color="#FFF"
                            />
                            Voltar
                        </h4>
                    </div>
                )
            }
            <div>
                <p className="western" align="center" style={{marginBottom: '0.14in'}}>
                    <font size={4} style={{fontSize: '16pt'}}>
                        <span lang="pt-PT"><b>POLÍTICA DE PRIVACIDADE</b></span>
                    </font>
                </p>
                <p className="western" style={{marginBottom: '0.14in'}}>
                    <br />
                    <br />
                </p>
                <p className="western" align="justify" style={{marginBottom: '0.14in'}}>I - OBJETIVOS GERAIS</p>
                <p className="western" align="justify" style={{marginBottom: '0.14in'}}>Reconhecendo a importância da proteção de dados pessoais enquanto mecanismo de efetivação da privacidade e de outros direitos individuais como liberdade de expressão e pensamento, dignidade, intimidade e inviolabilidade da vida privada, a ÁTRIO formaliza pelo presente instrumento o "aviso de privacidade", publicamente denominado "Politica de Privacidade", em que declara ao público em geral seu compromisso com a observância das leis de proteção de dados pessoais e com as melhores práticas de segurança de dados.</p>
                <p className="western" align="justify" style={{marginBottom: '0.14in'}}>Este documento apresenta, de forma preliminar e resumida, os princípios e procedimentos observados pela ÁTRIO quando do tratamento de dados pessoais de qualquer titular quando de sua interação com os canais de relacionamento da empresa, seja via <i>website</i>, seja por canais de comunicação como aplicativos, perfis e páginas em rede sociais ou qualquer outro veículo
                    <i>online</i>.</p>
                <p className="western" align="justify" style={{marginBottom: '0.14in'}}>As diretrizes e recomendações contempladas presumem-se de conhecimento de todos no ambiente ÁTRIO e traduzem o cumprimento dos princípios de transparência, boa-fé, finalidade legítima, necessidade de dados e prestação de contas.</p>
                <p className="western" align="justify" style={{marginBottom: '0.14in'}}>
                    <br />
                    <br />
                </p>
                <p className="western" align="justify" style={{marginBottom: '0.14in'}}>II - OBJETIVOS ESPECÍFICOS</p>
                <p className="western" align="justify" style={{marginBottom: '0.14in'}}>Além da declaração do compromisso com a privacidade por meio da proteção de dados, a Política de Privacidade se presta a:</p>
                <p className="western" align="justify" style={{marginBottom: '0.14in'}}>(i) Prestar esclarecimentos acerca das finalidades e formas de tratamento de dados pessoais promovidas pela ÁTRIO por seus veículos de relacionamento <i>online</i>;</p>
                <p className="western" align="justify" style={{marginBottom: '0.14in'}}>
                    (ii) Comunica o tipo de dados pessoais tratados pelos seus canais de relacionamento;
                </p>
                <p className="western" align="justify" style={{marginBottom: '0.14in'}}>(iii) Informa e orienta os titulares de dados sobre seus direitos e forma de solicitar correções, edições, acesso, oposição e eliminação de tratamento de dados pessoais.</p>
                <p className="western" style={{marginBottom: '0.14in'}}>
                    <br />
                    <br />
                </p>
                <p className="western" style={{marginBottom: '0.14in'}}>
                    <br />
                    <br />
                </p>
                <p className="western" align="center" style={{marginBottom: '0.14in'}}><u><b>POLÍTICA
                        DE PRIVACIDADE</b></u><b> – Aplicativo "Atrio Invest"</b></p>
                <table width={605} cellPadding={7} cellSpacing={0}>
                    <colgroup>
                        <col width={142} />
                        <col width={433} />
                    </colgroup>
                    <tbody>
                        <tr valign="top">
                            <td width={142} style={{border: '1px solid #000000', padding: '0in 0.08in'}}>
                                <p className="western" align="left" style={{orphans: 2, widows: 2}}>
                                    <span lang="pt-PT">Código:</span></p>
                            </td>
                            <td width={433} style={{border: '1px solid #000000', paddingTop: '0in', paddingBottom: '0in', paddingLeft: '0.07in', paddingRight: '0.08in'}}>
                                <p className="western">
                                    <span lang="pt-PT">1,0,0</span></p>
                            </td>
                        </tr>
                        <tr valign="top">
                            <td width={142} style={{border: '1px solid #000000', padding: '0in 0.08in'}}>
                                <p className="western" align="left" style={{orphans: 2, widows: 2}}>
                                    <span lang="pt-PT">Versão:</span></p>
                            </td>
                            <td width={433} style={{border: '1px solid #000000', paddingTop: '0in', paddingBottom: '0in', paddingLeft: '0.07in', paddingRight: '0.08in'}}>
                                <p className="western">
                                    <span lang="pt-PT">1,0</span></p>
                            </td>
                        </tr>
                        <tr valign="top">
                            <td width={142} style={{border: '1px solid #000000', padding: '0in 0.08in'}}>
                                <p className="western" align="left" style={{orphans: 2, widows: 2}}>
                                    <span lang="pt-PT">Data de versão:</span></p>
                            </td>
                            <td width={433} style={{border: '1px solid #000000', paddingTop: '0in', paddingBottom: '0in', paddingLeft: '0.07in', paddingRight: '0.08in'}}>
                                <p className="western" align="left" style={{orphans: 2, widows: 2}}>
                                    <span lang="pt-PT">28/02/2020</span></p>
                            </td>
                        </tr>
                        <tr valign="top">
                            <td width={142} style={{border: '1px solid #000000', padding: '0in 0.08in'}}>
                                <p className="western" align="left" style={{orphans: 2, widows: 2}}>
                                    <span lang="pt-PT">Criado por:</span></p>
                            </td>
                            <td width={433} style={{border: '1px solid #000000', paddingTop: '0in', paddingBottom: '0in', paddingLeft: '0.07in', paddingRight: '0.08in'}}>
                                <p className="western">
                                    <span lang="pt-PT">AVA Software Solutions</span></p>
                            </td>
                        </tr>
                        <tr valign="top">
                            <td width={142} style={{border: '1px solid #000000', padding: '0in 0.08in'}}>
                                <p className="western" align="left" style={{orphans: 2, widows: 2}}>
                                    <span lang="pt-PT">Aprovado por:</span></p>
                            </td>
                            <td width={433} style={{border: '1px solid #000000', paddingTop: '0in', paddingBottom: '0in', paddingLeft: '0.07in', paddingRight: '0.08in'}}>
                                <p className="western">
                                    <span lang="pt-PT">Capere Invest</span></p>
                            </td>
                        </tr>
                        <tr valign="top">
                            <td width={142} style={{border: '1px solid #000000', padding: '0in 0.08in'}}>
                                <p className="western" align="left" style={{orphans: 2, widows: 2}}>
                                    <span lang="pt-PT">Nível de Confidencialidade:</span></p>
                            </td>
                            <td width={433} style={{border: '1px solid #000000', paddingTop: '0in', paddingBottom: '0in', paddingLeft: '0.07in', paddingRight: '0.08in'}}>
                                <p className="western" align="left" style={{orphans: 2, widows: 2}}>
                                    <span lang="pt-PT">PÚBLICO</span></p>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <p className="western" style={{marginBottom: '0.14in'}}>
                    <br />
                    <br />
                </p>
                <p className="western" style={{marginBottom: '0.14in'}}>
                    <br />
                    <br />
                </p>
                <p className="western" style={{marginBottom: '0in', lineHeight: '100%'}}><b>POLÍTICA
                        DE PRIVACIDADE ÁTRIO</b></p>
                <p className="western" style={{marginBottom: '0in', lineHeight: '100%'}}>
                    <br />
                </p>
                <p className="western" style={{marginBottom: '0in', lineHeight: '100%'}}><b>A
                        ÁTRIO e Sua Privacidade</b></p>
                <p className="western" align="justify" style={{marginBottom: '0in', lineHeight: '100%'}}>
                    Reconhecemos o valor e importância de sua privacidade, e temos grande preocupação com a segurança e legitimidade do tratamento de seus dados pessoais. Queremos que fique claro para você nosso compromisso com o fiel cumprimento das normais de proteção de dados da República Federativa do Brasil (Lei nº 13709/18) e da União Europeia (Regulamento 16/679), e a forma como operamos dados pessoais.
                </p>
                <p className="western" align="justify" style={{marginBottom: '0in', lineHeight: '100%'}}>
                    <br />
                </p>
                <p className="western" align="justify" style={{marginBottom: '0in', lineHeight: '100%'}}>
                    O alcance desta política de privacidade está especificamente direcionado às atividades que envolvem tratamento de dados pessoais nas plataformas de serviços, aplicativos, sistemas e portais de Internet desenvolvidos e ofertados pela ATRIO HOTÉIS S.A (CNPJ/MF 80.732.928/0001-18). Seu relacionamento com os canais de comunicação da ÁTRIO é tratado de acordo com as declarações desse aviso de privacidade.</p>
                <p className="western" style={{marginBottom: '0in', lineHeight: '100%'}}>
                    <br />
                </p>
                <p className="western" style={{marginBottom: '0in', lineHeight: '100%'}}><b>Dados
                        Pessoais Tratados</b></p>
                <p className="western" align="justify" style={{marginBottom: '0in', lineHeight: '100%'}}>
                    A ÁTRIO informa que promove apenas o tratamento de dados pessoais necessários ao cadastramento de seu interesse em se relacionar com a ÁTRIO. Tais dados se limitam a: (i) nome; (ii) cpf; (iii) nome de usuário (<i>username</i>); (iv) senha; e (v) e-mail. Dos usuários cadastrados nos serviços do aplicativo "<i>Atrio Invest</i>" poderão ser tratados, ainda: (vi) dados necessários ao cumprimento de obrigações contratuais como informações bancárias e endereço pessoal ou domiciliar. Esses dados serão todos fornecidos por você quando interage com a ATRIO. Ao disponibilizar estas informações, a ATRIO considera que você o fez ciente dos termos desta política.</p>
                <p className="western" align="justify" style={{marginBottom: '0in', lineHeight: '100%'}}>
                    <br />
                </p>
                <p className="western" align="justify" style={{marginBottom: '0in', lineHeight: '100%'}}>
                    Além dos dados de cadastro para relacionamento, a ÁTRIO informa que promove a coleta de dados pessoais indiretos relacionados a seu acesso no Aplicativo ou aos portais na Internet, levantados pelo próprio sistema sem a necessária informação pelo usuário, tais como geolocalização e endereço de IP.</p>
                <p className="western" style={{marginBottom: '0in', lineHeight: '100%'}}>
                    <br />
                </p>
                <p className="western" style={{marginBottom: '0in', lineHeight: '100%'}}><b>Finalidade
                        do Tratamento</b></p>
                <p className="western" align="justify" style={{marginBottom: '0in', lineHeight: '100%'}}>
                    O tratamento de dados coletados pela ÁTRIO se dará em plena conformidade com a legislação brasileira e europeia de proteção de dados e no estrito propósito de atendimento às seguintes finalidades:
                </p>
                <p className="western" align="justify" style={{marginLeft: '0.49in', marginBottom: '0in', lineHeight: '100%'}}>
                    (a) execução dos serviços contratados junto à ÁTRIO, bem como para promoção de novas funcionalidades, atualizações, politica de preços e de contratação;</p>
                <p className="western" align="justify" style={{marginLeft: '0.49in', marginBottom: '0in', lineHeight: '100%'}}>
                    (b) divulgação de conteúdo relacionado às atividades exercidas e produtos e serviços oferecidos pela ÁTRIO;
                </p>
                <p className="western" align="justify" style={{marginLeft: '0.49in', marginBottom: '0in', lineHeight: '100%'}}>
                    (c) estudar a interação dos usuários com os meios de comunicação da ÁTRIO, mediante pseudonimização, no propósito de desenvolver melhorias e novas formas de relacionamento;</p>
                <p className="western" align="justify" style={{marginLeft: '0.49in', marginBottom: '0in', lineHeight: '100%'}}>
                    (d) estabelecer critérios de identificação do usuário cadastrado e suas atividades na plataforma, aplicativo e website;</p>
                <p className="western" align="justify" style={{marginLeft: '0.49in', marginBottom: '0in', lineHeight: '100%'}}>
                    (e) evitar a ocorrência de fraudes contra o usuário e terceiros;
                </p>
                <p className="western" style={{marginBottom: '0in', lineHeight: '100%'}}>
                    <br />
                </p>
                <p className="western" style={{marginBottom: '0in', lineHeight: '100%'}}><b>Tratamento
                        de Dados</b></p>
                <p className="western" align="justify" style={{marginBottom: '0in', lineHeight: '100%'}}>
                    Ao coletar os dados pessoais, a ÁTRIO assegura que os armazenará em condições e estrutura tecnológica seguras, mediante aplicação dos mais avançados critérios técnicos de segurança da informação.</p>
                <p className="western" align="justify" style={{marginBottom: '0in', lineHeight: '100%'}}>
                    O armazenamento dos dados se dará pelo período mínimo de 6 (seis) meses, em conformidade com as exigências do Marco Civil da Internet (MCI) brasileiro, e se estenderá até que a relação entre o usuário e a ÁTRIO perdure, observado o prazo legal de registro de logs de acesso instituídos pela lei citada.</p>
                <p className="western" align="justify" style={{marginBottom: '0in', lineHeight: '100%'}}>
                    <br />
                </p>
                <p className="western" align="justify" style={{marginBottom: '0in', lineHeight: '100%'}}>
                    <b>Segurança de Dados</b></p>
                <p className="western" align="justify" style={{marginBottom: '0in', lineHeight: '100%'}}>
                    Os dados pessoais obtidos pela ÁTRIO serão armazenados em ambiente tecnológico com controle de restrição de acesso. As informações serão disponibilizadas apenas ao profissional ou setor que efetivamente os necessite para execução de algumas das finalidades declaradas do processamento de dados.</p>
                <p className="western" align="justify" style={{marginBottom: '0in', lineHeight: '100%'}}>
                    <font>Embora nenhum método seja totalmente
                        seguro, nossas práticas de segurança de dados são
                        revisadas periodicamente e seguem as recomendações da
                        Lei Geral de Proteção de Dados.</font></p>
                <p className="western" style={{marginBottom: '0in', lineHeight: '100%'}}>
                    <br />
                </p>
                <p className="western" style={{marginBottom: '0in', lineHeight: '100%'}}><b>Compartilhamento
                        de Dados</b></p>
                <p className="western" align="justify" style={{marginBottom: '0in', lineHeight: '100%'}}>
                    Os dados de natureza pessoal do Usuário somente poderão ser compartilhados pela ÁTRIO com terceiros, nas hipóteses de:
                </p>
                <p className="western" align="justify" style={{marginLeft: '0.49in', marginBottom: '0in', lineHeight: '100%'}}>
                    (a) sucessão empresarial, a exemplo de fusão, aquisição e incorporação;</p>
                <p className="western" align="justify" style={{marginLeft: '0.49in', marginBottom: '0in', lineHeight: '100%'}}>
                    (b) contratação de serviços de processamento de dados junto a terceiros (operadores), tais como hospedagem de dados, sistemas de processamento (<i>serviços de computação
                        em nuvem</i>), consultoria em tecnologia da informação, serviços de telecomunicação, serviços de entrega, serviços de publicidade e marketing, organização de eventos, serviços de faturamento de créditos (financeiros), serviços de viagens, ou outras ações que envolvam a oferta de benefícios ou conteúdo de interesse do titular do dado pessoal.</p>
                <p className="western" align="justify" style={{marginBottom: '0in', lineHeight: '100%'}}>
                    O acesso destes terceiros - sob a contratação da ÁTRIO - a dados dos usuários se dará mediante exigência de compromisso de confidencialidade e conformidade com as leis de proteção de dados vigentes, em especial com a Lei nº 13.709/2018 a ser reduzida a termo em instrumento contratual ou em declaração específica com força obrigacional.
                </p>
                <p className="western" align="justify" style={{marginBottom: '0in', lineHeight: '100%'}}>
                    A ÁTRIO informa ao titular que é obrigada por lei a compartilhar dados pessoais dos usuários em caso de exigência emanada de autoridade judicial, policial ou administrativa. A disponibilização destes dados se dará apenas mediante validação da ordem emitida junto aos órgãos competentes.
                </p>
                <p className="western" style={{marginBottom: '0in', lineHeight: '100%'}}>
                    <br />
                </p>
                <p className="western" style={{marginBottom: '0in', lineHeight: '100%'}}><b>Transferência
                        Internacional de Dados</b></p>
                <p className="western" align="justify" style={{marginBottom: '0in', lineHeight: '100%'}}>
                    Alguns dos processadores diretos e indiretos poderão estar fora do território brasileiro. A ÁTRIO se compromete a zelar e exigir o cumprimento das exigências legais decorrentes das normas de proteção de dados pessoais. Em especial, a ÁTRIO se compromete a exigir de terceiros a assinatura de Compromisso com os Termos de Segurança da informação e Proteção de Dados Pessoais.
                </p>
                <p className="western" align="justify" style={{marginBottom: '0in', lineHeight: '100%'}}>
                    Ao declarar ciência e aceite dos termos desta Política de Privacidade, o usuário manifesta seu inequívoco e expresso consentimento com a eventual transferência de dados em atenção à ordem emanada por autoridades oficiais de países de fora do território brasileiro. A ÁTRIO esclarece ainda que o consentimento em questão é dispensável caso o fornecimento das informações às autoridades decorra de exigência legal.</p>
                <p className="western" style={{marginBottom: '0in', lineHeight: '100%'}}>
                    <br />
                </p>
                <p className="western" style={{marginBottom: '0in', lineHeight: '100%'}}><b>Do
                        Uso de </b><i><b>LocalStorage</b></i></p>
                <p className="western" align="justify" style={{marginBottom: '0in', lineHeight: '100%'}}>
                    A ÁTRIO declara fazer uso de <i>LocalStorage</i> em suas plataformas de negócios e aplicativos operados no ambiente da Internet. <i>LocalStorage</i> são arquivos enviados pela ÁTRIO e instalados no navegador do titular que permitem a identificação do terminal de Internet, autenticar o usuário, personalizar a experiência de navegação e contratação de serviços, registrar acessos e atividades na página.
                </p>
                <p className="western" align="justify" style={{marginBottom: '0in', lineHeight: '100%'}}>
                    A ÁTRIO faz uso de <i>LocalStorage</i> para execução de:
                </p>
                <p className="western" style={{marginLeft: '0.49in', marginBottom: '0in', lineHeight: '100%'}}>
                    (a) autenticação e segurança</p>
                <p className="western" style={{marginLeft: '0.49in', marginBottom: '0in', lineHeight: '100%'}}>
                    (b) medição de desempenho e estatísticas{/* Avaliar que tipo de cookies estamos empregando no aplicativo WEB ou em portal, se for o caso */}</p>
                <p className="western" align="justify" style={{marginBottom: '0in', lineHeight: '100%'}}>
                    A ÁTRIO assegura que tanto na execução de
                    <i>LocalStorage</i> próprios quanto de terceiros, as melhores práticas de segurança são adotadas para garantir a proteção dos dados pessoais levantados.</p>
                <p className="western" align="justify" style={{marginBottom: '0in', lineHeight: '100%'}}>
                    O uso dos <i>LocalStorage</i> pela ÁTRIO é previamente autorizado pelo usuário quando de seu acesso às plataformas e <i>websites</i> online.{/* Temos condiççoe de ofertar desde já esse controle aos titulares? Se não for ossível adiamos e ajustamos a minuta em momento posterior. */}
                </p>
                <p className="western" style={{marginBottom: '0in', lineHeight: '100%'}}>
                    <br />
                </p>
                <p className="western" style={{marginBottom: '0in', lineHeight: '100%'}}><b>Dos
                        Direitos do Titular</b></p>
                <p className="western" align="justify" style={{marginBottom: '0in', lineHeight: '100%'}}>
                    A ÁTRIO assegura ao usuário o direito de acesso aos dados pessoais em uso. Além do acesso, é garantido ao usuário:
                </p>
                <p className="western" style={{marginLeft: '0.49in', marginBottom: '0in', lineHeight: '100%'}}>
                    (a) o direito à retificação de seus dados;</p>
                <p className="western" style={{marginLeft: '0.49in', marginBottom: '0in', lineHeight: '100%'}}>
                    (b) o direito à portabilidade dos dados, se aplicável;</p>
                <p className="western" align="justify" style={{marginLeft: '0.49in', marginBottom: '0in', lineHeight: '100%'}}>
                    (c) o direito à oposição do uso dos dados para finalidade previamente autorizada;</p>
                <p className="western" align="justify" style={{marginLeft: '0.49in', marginBottom: '0in', lineHeight: '100%'}}>
                    (d) o direito à exclusão dos dados, ressalvada a manutenção dos dados necessários ao cumprimento de dever legal e ao exercício do direito de defesa em processo judicial e/ou administrativo;</p>
                <p className="western" align="justify" style={{marginBottom: '0in', lineHeight: '100%'}}>
                    A ÁTRIO assegura que, encerrada a relação contratual ou o interesse na relação, manifestada a oposição de tratamento, ou revogado o consentimento para tratamento dos dados, as informações de natureza pessoal, coletadas e armazenadas, serão eliminadas, ressalva feita à manutenção dos registros necessários ao cumprimento de dever legal ou para eventual defesa em processo judicial ou administrativo.</p>
                <p className="western" style={{marginBottom: '0in', lineHeight: '100%'}}>
                    <br />
                </p>
                <p className="western" style={{marginBottom: '0in', lineHeight: '100%'}}><b>Isenção
                        de Responsabilidade</b></p>
                <p className="western" align="justify" style={{marginBottom: '0in', lineHeight: '100%'}}>
                    A ÁTRIO não se responsabiliza pela negligência do usuário com seus próprios dados pessoais. A responsabilidade da ÁTRIO se limita aos esforços sobre a garantia do emprego das melhores práticas de segurança. Cabe ao usuário zelar pela confidencialidade de informações relacionadas ao acesso e operação de seus dados no
                    <i>website</i> da ÁTRIO, tais como informações de <i>login</i>, senha, chaves de segurança e qualquer outra solução implementada pela {/* Não sei se existe necessidade desse tipo de cuidado nos sites ou serviços online. */}ÁTRIO.</p>
                <p className="western" style={{marginBottom: '0in', lineHeight: '100%'}}>
                    <br />
                </p>
                <p className="western" style={{marginBottom: '0in', lineHeight: '100%'}}><font><b>Atualizações
                        em nossa Política de Privacidade</b></font></p>
                <p className="western" style={{marginBottom: '0in', lineHeight: '100%'}}><font>Esta
                        Política de Privacidade pode ser atualizada e modificada
                        periodicamente e sem aviso prévio. Indicamos, ao término
                        deste documento, a data da última atualização.</font></p>
                <p className="western" style={{marginBottom: '0in', lineHeight: '100%'}}>
                    <br />
                </p>
                <p className="western" style={{marginBottom: '0in', lineHeight: '100%'}}>
                    <b>Contatos, Dúvidas e Solicitações</b></p>
                <p className="western" align="justify" style={{marginBottom: '0in', lineHeight: '100%'}}>
                    Para solicitação de esclarecimentos, dúvidas e solicitações de qualquer natureza relacionadas ao tratamento de dados pessoais e aos termos da presente política de privacidade, a ÁTRIO se coloca à disposição para contato por e-mail privacidade@atrio.com.br.</p>
                <p className="western" align="justify" style={{marginBottom: '0in', lineHeight: '100%'}}>
                    <br />
                </p>
                <p className="western" align="justify" style={{marginBottom: '0in', lineHeight: '100%'}}>
                    Publicação da Versão:</p>
                <p className="western" align="justify" style={{marginBottom: '0in', lineHeight: '100%'}}>
                    28 de fevereiro de 2020.</p>
            </div>
            <br />
            <br />
            <br />
            <div>
                <p className="western" align="center" style={{marginBottom: '0.14in'}}>
                    <font size={4} style={{fontSize: '16pt'}}><span lang="pt-PT"><b>NOTIFICAÇÃO
                            - POLÍTICA DE PRIVACIDADE </b></span></font>
                </p>
                <p className="western" style={{marginBottom: '0.14in'}}>
                    <br />
                </p>
                <p className="western" align="justify" style={{marginBottom: '0.14in'}}>I - OBJETIVOS GERAIS</p>
                <p className="western" align="justify" style={{marginBottom: '0.14in'}}>Reconhecendo a importância da proteção de dados pessoais enquanto mecanismo de efetivação da privacidade e de outros direitos individuais como liberdade de expressão e pensamento, dignidade, intimidade e inviolabilidade da vida privada, a ÁTRIO formaliza pelo presente instrumento o "aviso de privacidade", publicamente denominado "Politica de Privacidade", em que declara ao público em geral seu compromisso com a observância das leis de proteção de dados pessoais e com as melhores práticas de segurança de dados.</p>
                <p className="western" align="justify" style={{marginBottom: '0.14in'}}>Este documento trata da notificação objetiva e resumida que sugere ao usuário que tome conhecimento da Política de Privacidade da Empresa quando do primeiro acesso ao aplicativo WEB ou ao aplicativo mobile.</p>
                <p className="western" align="justify" style={{marginBottom: '0.14in'}}>
                    <br />
                    <br />
                </p>
                <p className="western" align="center" style={{marginBottom: '0.14in'}}><u><b>NOTIFICAÇÃO
                        DA POLÍTICA DE PRIVACIDADE</b></u><b> – Aplicativo
                        "Atrio Invest"</b></p>
                <table width={605} cellPadding={7} cellSpacing={0}>
                    <colgroup>
                        <col width={142} />
                        <col width={433} />
                    </colgroup>
                    <tbody>
                        <tr valign="top">
                            <td width={142} style={{border: '1px solid #000000', padding: '0in 0.08in'}}>
                                <p className="western" align="left" style={{orphans: 2, widows: 2}}>
                                    <span lang="pt-PT">Código:</span></p>
                            </td>
                            <td width={433} style={{border: '1px solid #000000', paddingTop: '0in', paddingBottom: '0in', paddingLeft: '0.07in', paddingRight: '0.08in'}}>
                                <p lang="pt-PT" className="western">
                                    <br />
                                </p>
                            </td>
                        </tr>
                        <tr valign="top">
                            <td width={142} style={{border: '1px solid #000000', padding: '0in 0.08in'}}>
                                <p className="western" align="left" style={{orphans: 2, widows: 2}}>
                                    <span lang="pt-PT">Versão:</span></p>
                            </td>
                            <td width={433} style={{border: '1px solid #000000', paddingTop: '0in', paddingBottom: '0in', paddingLeft: '0.07in', paddingRight: '0.08in'}}>
                                <p className="western">
                                    <span lang="pt-PT">1,0</span></p>
                            </td>
                        </tr>
                        <tr valign="top">
                            <td width={142} style={{border: '1px solid #000000', padding: '0in 0.08in'}}>
                                <p className="western" align="left" style={{orphans: 2, widows: 2}}>
                                    <span lang="pt-PT">Data de versão:</span></p>
                            </td>
                            <td width={433} style={{border: '1px solid #000000', paddingTop: '0in', paddingBottom: '0in', paddingLeft: '0.07in', paddingRight: '0.08in'}}>
                                <p className="western" align="left" style={{orphans: 2, widows: 2}}>
                                    <span lang="pt-PT">28/02/2020</span></p>
                            </td>
                        </tr>
                        <tr valign="top">
                            <td width={142} style={{border: '1px solid #000000', padding: '0in 0.08in'}}>
                                <p className="western" align="left" style={{orphans: 2, widows: 2}}>
                                    <span lang="pt-PT">Criado por:</span></p>
                            </td>
                            <td width={433} style={{border: '1px solid #000000', paddingTop: '0in', paddingBottom: '0in', paddingLeft: '0.07in', paddingRight: '0.08in'}}>
                                <p className="western">
                                    <span lang="pt-PT">AVA Software Solutions</span></p>
                            </td>
                        </tr>
                        <tr valign="top">
                            <td width={142} style={{border: '1px solid #000000', padding: '0in 0.08in'}}>
                                <p className="western" align="left" style={{orphans: 2, widows: 2}}>
                                    <span lang="pt-PT">Aprovado por:</span></p>
                            </td>
                            <td width={433} style={{border: '1px solid #000000', paddingTop: '0in', paddingBottom: '0in', paddingLeft: '0.07in', paddingRight: '0.08in'}}>
                                <p className="western">
                                    <span lang="pt-PT">Capere Invest</span></p>
                            </td>
                        </tr>
                        <tr valign="top">
                            <td width={142} style={{border: '1px solid #000000', padding: '0in 0.08in'}}>
                                <p className="western" align="left" style={{orphans: 2, widows: 2}}>
                                    <span lang="pt-PT">Nível de Confidencialidade:</span></p>
                            </td>
                            <td width={433} style={{border: '1px solid #000000', paddingTop: '0in', paddingBottom: '0in', paddingLeft: '0.07in', paddingRight: '0.08in'}}>
                                <p className="western" align="left" style={{orphans: 2, widows: 2}}>
                                    <span lang="pt-PT">PÚBLICO</span></p>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <p className="western" style={{marginBottom: '0.14in'}}>
                    <br />
                    <br />
                </p>
                <p className="western" style={{marginBottom: '0.14in'}}>
                    <br />
                    <br />
                </p>
                <p className="western" style={{marginBottom: '0.14in'}}><b>Aviso de
                        Privacidade</b></p>
                <p className="western" align="left" style={{marginBottom: '0.14in', lineHeight: '115%', orphans: 2, widows: 2}}>
                    Na execução do ATRIO INVEST coletamos e fazemos uso de alguns de seus dados pessoais e empregamos <i>localstorage</i> em seu dispositivo. Para saber mais, leia nossa Política de Privacidade.
                </p>
            </div>
        </div>
    )
};

export default Terms;

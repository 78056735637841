import React, { Component } from "react";
import { Row, Col, Card, CardHeader, CardBody, CardTitle } from "reactstrap";
import { periods } from "data";
import renderHTML from 'react-render-html';

import Api from "../../api";
import Loading from "components/Loading";
import { connect } from "react-redux";

import { showMessage } from "../../actions";

const returnString = period => (period < 10 ? "0" + period : period);

const formatDate = timestamp => {
  const obj = new Date(timestamp);

  return `${returnString(obj.getDate())} de ${periods[
    obj.getMonth()
  ].toLowerCase()} de ${obj.getFullYear()}`;
};

class Notification extends Component {
  state = {
    notificationList: [],
    loading: true
  }

  componentDidMount() {
    Api.get("investidor/notifications").then(({data}) => {
      if (!data.data) {
        this.setState({loading: false});
        this.props.showMessage({message: "Erro ao carregar as suas notificações", open: true});
        return false;
      }

      this.setState({notificationList: data.data, loading: false});
    })

    Api.put("investidor/notifications/update");
  }

  render() {
    const { notificationList, loading } = this.state;

    return (
      <div className="content">
        <Row>
          <Col lg="12">
            <CardHeader
              style={{ backgroundColor: "#1d1d2c", paddingBottom: 0 }}
            >
              <CardTitle
                tag="h3"
                style={{
                  display: "flex",
                  justifyContent: "space-between"
                }}
                >
                Notificações
              </CardTitle>
            </CardHeader>
          </Col>
        </Row>
        {loading ? <Loading /> : null}
        {notificationList.map(notify => (
          <Row>
            <Col lg="12">
              <Row>
                <Col sm="12">
                  <Card>
                    <CardHeader style={{ borderBottom: "1px solid #000" }}>
                      <CardTitle
                        style={{
                          display: "flex",
                          justifyContent: "space-between"
                        }}
                        >
                        <div>
                            <div style={{backgroundColor: notify.seen ? 'grey' : 'red', width: 10, height: 10, borderRadius: 5, position: 'absolute', left: 16, top: 18}}/>
                            <span style={{marginLeft: 20}}>{notify.title}</span>
                          
                          {
                            notify.link &&
                            <span
                                color="info"
                                style={{ fontWeight: "normal", cursor: "pointer", marginLeft: 5, textDecoration: 'underline', color: '#1eb4e3' }}
                                onClick={() => {window.open(notify.link, '_blank')}}
                            >
                              acessar link
                            </span>
                          }
                        </div>
                        <span style={{ color: "#FE2265" }}>
                          {formatDate(notify.time * 1000)}
                        </span>
                      </CardTitle>
                    </CardHeader>
                    <CardBody>{renderHTML(notify.text)}</CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        ))}
      </div>
    );
  }
}

export default connect(null, { showMessage })(Notification);

import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

import { periods } from "data";
import Api from 'api';

import { showMessage } from "../../actions";

const PeriodInfo = ({numdoc, setMonthReference, showMessage}) => {
    const [date, setDate] = useState("")
    
    useEffect(() => {
        Api.get("investidor/app/mes-referencia", {
            params: {
                NUMDOCUMENTO: numdoc
            }
        }).then(({data}) => {
            if (data.status === "error") {
                showMessage({message: "Erro não esperado", open: true});
            } else {
                setDate(`${periods[data.data.MES - 1]}/${data.data.ANO}`);
                setMonthReference(data.data.MES)
            }
        })
    }, []);

    return <span style={{ color: "#d2d2d5" }}>{date || (
        <SkeletonTheme color="#272A3C" highlightColor="#46495B">
            <Skeleton width={100} height={20} />
        </SkeletonTheme>
    )}</span>
};

export default connect(null, { showMessage })(PeriodInfo);

import axios from 'axios';

export const getCancelToken = () => {
  const CancelToken = axios.CancelToken;

  return CancelToken.source();
};

const Api = axios.create({
  baseURL: process.env.REACT_APP_ENDPOINT,
});

Api.interceptors.request.use(config => {
  const token = localStorage.getItem('token')

  if (config.url !== "investidor/auth/login" && config.url !== "investidor/auth/recover" && config.url !== "investidor/auth/recovery") {
      config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

export default Api;

import React, { useState, useEffect, memo } from "react";
import CurrencyFormatter from "currency-formatter";
import { Card, CardHeader, CardBody, CardTitle, Table, Col } from "reactstrap";

const getColor = value => {
  if (value > 0) {
    return "#00FFC8";
  }

  return "#FE2265";
};

const CardData = props => {
  const [data, setData] = useState({});

  useEffect(() => {
    const { actual, previous, month } = props;
    let actualTotal = 0, actualMonth = 0, previousTotal = 0, previousMonth = 0;

    if (actual.length > 0) {
      // Ano atual acumulado
      if (props.acumType === 2) {
        actualTotal = actual.filter(item => item.MES <= month + 1).reduce((previousValue, currentValue) => previousValue + currentValue.VALOR, 0);
      } else {
        actualTotal = actual.reduce((previousValue, currentValue) => previousValue + currentValue.VALOR, 0);
        actualTotal /= actual.length
      }
      // Ano atual mes atual
      const period = actual.find(item => item.MES === month + 1);
      if (period) {
          actualMonth = period.VALOR;
      }
    }

    if (previous.length > 0) {
      // Ano passado acumulado
      if (props.acumType === 2) {
        previousTotal = previous.filter(item => item.MES <= month + 1).reduce((previousValue, currentValue) => previousValue + currentValue.VALOR, 0);
      } else {
        previousTotal = previous.reduce((previousValue, currentValue) => previousValue + currentValue.VALOR, 0);
        previousTotal /= previous.length
      }
      // Ano passado mes atual
      const period = previous.find(item => item.MES === month + 1);
      if (period) {
          previousMonth = period.VALOR;
      }
    }
      
    setData({
      actual: actualMonth,
      previous: previousMonth,
      variant: previousMonth > -0.001 && previousMonth < 0.001 ? actualMonth === 0 ? 0 : 100 : ((actualMonth/previousMonth) - 1) * 100,
      
      actualAccum: actualTotal,
      previousAccum: previousTotal,
      variantAccum: previousTotal > -0.001 && previousTotal < 0.001 ? actualTotal === 0 ? 0 : 100 : ((actualTotal/previousTotal) - 1) * 100,
    });
  }, [props.month]);


  const formatValue = (value = 0, type = "%") => {
    if (type === "%") {
      return (
        <span style={{ color: getColor(value) }}>{`${value.toFixed(
          2
        )} %`}</span>
      );
    }

    return (
      <span style={{ color: getColor(value) }}>
        {CurrencyFormatter.format(value, { code: "BRL" })}
      </span>
    );
  };

  return (
    <Col lg="6">
      <Card className="card-chart">
        <CardHeader style={{ borderBottom: "1px solid #000" }}>
          <CardTitle tag="h3">{props.title}</CardTitle>
        </CardHeader>
        <CardBody>
          <Table className="tablesorter" responsive>
            <thead className="text-primary">
              <tr>
                <th style={{ width: "33%" }}>Ano</th>
                <th style={{ width: "33%" }}>Mês</th>
                <th style={{ width: "33%" }}>{props.acumType === 2 ? 'Acumulado' : 'Média'}/ano</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th
                  style={{ fontWeight: "normal", color: "#F5F7FA", width: "33%" }}
                >
                  {props.previousLabel}
                </th>
                <th
                  style={{ fontWeight: "normal", color: "#F5F7FA", width: "33%" }}
                >
                  {formatValue(data.previous, props.type)}
                </th>
                <th
                  style={{ fontWeight: "normal", color: "#F5F7FA", width: "33%" }}
                >
                  {formatValue(data.previousAccum, props.type)}
                </th>
              </tr>

              <tr>
                <th
                  style={{ fontWeight: "normal", color: "#F5F7FA", width: "33%" }}
                >
                  {props.actualLabel}
                </th>
                <th
                  style={{ fontWeight: "normal", color: "#F5F7FA", width: "33%" }}
                >
                  {formatValue(data.actual, props.type)}
                </th>
                <th
                  style={{ fontWeight: "normal", color: "#F5F7FA", width: "33%" }}
                >
                  {formatValue(data.actualAccum, props.type)}
                </th>
              </tr>

              <tr>
                <th
                  style={{ fontWeight: "normal", color: "#F5F7FA", width: "33%" }}
                >
                  Var
                </th>
                <th
                  style={{ fontWeight: "normal", color: "#F5F7FA", width: "33%" }}
                >
                  {formatValue(data.variant)}
                </th>
                <th
                  style={{ fontWeight: "normal", color: "#F5F7FA", width: "33%" }}
                >
                  {formatValue(data.variantAccum)}
                </th>
              </tr>
            </tbody>
          </Table>
        </CardBody>
      </Card>
    </Col>
  );
};

export default memo(CardData);

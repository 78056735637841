import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Col,
  Input,
  Button
} from "reactstrap";
import Api from "../../api";
import { showMessage } from "../../actions";

import Loading from "components/Loading";

class Contact extends Component {
  state = {
    message: "",
    subject: "",
    loading: false
  }

  handleSendMessage = async () => {
    const { message, subject, loading } = this.state;

    if (loading) {
      return;
    }

    this.setState({loading: true});

    if (message === "" || subject === "" || this.props.user.email === "") {
        this.props.showMessage({message: "Erro ao enviar a mensagem, verifique todos os campos e tente novamente.", open: true});
      this.setState({loading: false});
      return false;
    }

    try {
      await Api.post("/investidor/auth/contact", {
        body: message,
        subject: subject,
        email: this.props.user.email
      });
      this.setState({loading: false});
    } catch (e) {
      console.warn(e);
      this.props.showMessage({message: "Erro ao enviar a mensagem, tente novamente mais tarde.", open: true});
      this.setState({loading: false});
    }
  }


  render() {
    const {message, subject, loading} = this.state;

    return (
      <div className="content">
        <CardHeader
          style={{ backgroundColor: 'inherit', paddingBottom: 0 }}
        >
          <CardTitle
            tag="h3"
            style={{
              display: "flex",
              justifyContent: "space-between",
              height: "100%"
            }}
          >
            Contato
          </CardTitle>
        </CardHeader>
        <Card>
          <CardBody>
            <Row>
              <Col lg="12">
                <Input
                  value={subject}
                  onChange={e => this.setState({subject: e.target.value})}
                  placeholder="Assunto"
                  style={{ backgroundColor: "#1e1e2f", marginBottom: 10 }}
                />
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                <Input
                  value={message}
                  onChange={e => this.setState({message: e.target.value})}
                  placeholder="Mensagem"
                  type="textarea"
                  style={{
                    backgroundColor: "#1e1e2f",
                    marginBottom: 10,
                    height: 200,
                    padding: 10
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col
                lg="12"
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Button
                  color="info"
                  onClick={this.handleSendMessage}
                >
                  {
                    loading ? <Loading size={20} /> : 'Enviar'
                  }
                </Button>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({user}) => ({user});

export default connect(mapStateToProps, { showMessage })(Contact);

import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { Card, CardHeader, CardBody, CardTitle, Nav, NavItem, NavLink } from "reactstrap";
import { format } from "currency-formatter";
import Loading from "components/Loading";
import { RowDetailState, DataTypeProvider } from "@devexpress/dx-react-grid";
import {
  Grid,
  Table,
  TableHeaderRow,
  TableRowDetail
} from "@devexpress/dx-react-grid-bootstrap4";
import Api from "../../api";
import { connect } from "react-redux";
import { showMessage } from "../../actions";
import { periods as periodNameList } from "data";

const currencyFormatter = ({ value, ...rest }) => (
  <span style={{ color: "#FFFFFFB3" }}>
    {format(value, { code: "BRL" })}
  </span>
);

const CurrencyTypeProvider = props => (
  <DataTypeProvider {...props} formatterComponent={currencyFormatter} />
);

const filter = (accumType, data, ano, mes) => {
  if (accumType === 1) {
    return parseInt(data.ANO, 10) === ano;
  }

  return parseInt(data.ANO, 10) === ano && parseInt(data.MES, 10) === mes;
}

const gridDetailContainer = (accumType, numdoc, detailUrl, showMessage) => ({ row }) => {
  const [activeTab, setTab] = useState(0);
  const [detailData, setDetailData] = useState([]);
  const [detailData2, setDetailData2] = useState([]);
  const [loading, setLoading] = useState(true);

  useState(() => {
    Api.get(detailUrl, {
      params: {
        NUMDOCUMENTO: numdoc,
        ANO: row.ANO
      }
    }).then(({data}) => {
      if (data.status === "error") {
        showMessage({message: "Erro ao carregar os dados", open: true});
      } else {
        setDetailData(data.data.filter(item => filter(accumType, item, parseInt(row.ANO, 10), parseInt(row.mes, 10))));
        setLoading(false);
      }
    });

    Api.get(detailUrl, {
      params: {
        NUMDOCUMENTO: numdoc,
        ANO: row.ANO - 1
      }
    }).then(({data}) => {
      if (data.status === "error") {
        showMessage({message: "Erro ao carregar os dados", open: true});
      } else {
        setDetailData2(data.data.filter(item => filter(accumType, item, row.ANO - 1, parseInt(row.mes, 10))));
      }
    });
  }, [row.ANO, row.mes]);

  return (
    <div style={{ padding: "10px 40px" }}>
      {
        accumType === 0 ? (
          <Nav tabs>
            <NavItem style={{ cursor: "pointer", border: "none !important" }}>
              <NavLink className={classNames({ active: activeTab === 0 })} onClick={() => {
                setTab(0)
              }}>
                <h4>Hoteis - {periodNameList[row.mes - 1] + "/" + row.ANO}</h4>
              </NavLink>
            </NavItem>
            <NavItem style={{ cursor: "pointer", border: "none !important" }}>
              <NavLink className={classNames({ active: activeTab === 1 })} onClick={() => {
                setTab(1)
              }}>
                <h4>{periodNameList[row.mes - 1] + "/" + (row.ANO - 1)}</h4>
              </NavLink>
            </NavItem>
          </Nav>
        ) : <h4>Hoteis</h4>
      }

      <Card>
        <CardBody>
          { loading && <Loading />}
          {
            !loading && 
            <Grid
            rows={activeTab === 0 ? detailData : detailData2 }
            columns={[
              {
                title: "Nome",
                name: "HOTEL"
              },
              {
                title: "Unidades",
                name: "UNIDADES"
              },
              {
                title: "Rend. líquida",
                name: "RENDALIQUIDA"
              }
            ]}
          >
            <CurrencyTypeProvider for={["RENDALIQUIDA"]} />
            <Table 
              columnExtensions={[
                { columnName: 'HOTEL', align: 'left' },
                { columnName: 'UNIDADES', align: 'right' },
                { columnName: 'RENDALIQUIDA', align: 'right' },
              ]}
            />
            <TableHeaderRow />
          </Grid>
          }
        </CardBody>
      </Card>
    </div>
  );
};

const CardTable = ({numdoc, url, detailUrl, detailPastUrl, title, column, accumType, showMessage}) => {
  const [rows, setRow] = useState(-1);
  const [data, setData] = useState([]);
  
  useEffect(() => {
    Api.get(url, {
      params: {
        NUMDOCUMENTO: numdoc
      }
    }).then(({data}) => {
      if (data.status === "error") {
        showMessage({message: "Erro ao carregar os dados", open: true});
      } else {
        if (accumType === 1) {
          setData(data.data.reverse())
        } else {
          setData(data.data)
        }
      }
    });
  }, []);

  return (
    <Card>
      <CardHeader style={{ borderBottom: "1px solid #000" }}>
        <CardTitle
          tag="h3"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          {title}
        </CardTitle>
      </CardHeader>
      <CardBody>
        <Grid rows={data} columns={column}>
          <CurrencyTypeProvider
            for={["VALORFUNDORESERVA", "VALORIRRF", "RENDALIQUIDA"]}
          />
          <RowDetailState
            expandedRowIds={[rows]}
            onExpandedRowIdsChange={([, row]) => {
              setRow(row);
            }}
          />
          <Table 
            columnExtensions={[
              { columnName: 'VALORFUNDORESERVA', align: 'right' },
              { columnName: 'VALORIRRF', align: 'right' },
              { columnName: 'RENDALIQUIDA', align: 'right' },
              { columnName: 'UNIDADES', align: 'right' },
            ]}
          />
          <TableHeaderRow />
          <TableRowDetail
            contentComponent={gridDetailContainer(accumType, numdoc, detailUrl, detailPastUrl, showMessage)}
          />
        </Grid>
      </CardBody>
    </Card>
  );
};

export default connect(null, { showMessage })(CardTable);

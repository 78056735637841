import React, { Component } from "react";
import { Card, CardBody, Badge } from "reactstrap";

import { SearchState, IntegratedFiltering, GroupingState, CustomGrouping } from "@devexpress/dx-react-grid";
import {
  Grid,
  Table,
  Toolbar,
  SearchPanel,
  TableHeaderRow,
  TableGroupRow
} from "@devexpress/dx-react-grid-bootstrap4";
import Loading from "../../components/Loading";

class CardTable extends Component {
  getChildGroups = groups => {

    console.log(groups.map(group => ({ key: group.date, childRows: group.items })))
  
    return groups.map(group => ({ key: group.date, childRows: group.items }))
  };
  
  render() {
    const { reports, loading } = this.props;

    const unique = arr => {
      return arr.filter(function (value, index, self) { 
        return self.indexOf(value) === index;
      });
    }

    let rows = unique(reports.map(report => report.year + "/" + report.month));

    return (
      <Card>
        <CardBody>
          {
            loading ? <Loading /> : (
              <Grid
                rows={rows.map(row => {
                  const [year, month] = row.split(/\//g)

                  return {
                    date: row,
                    items: reports.filter(report => report.year === year && report.month === month)
                  }
                })}
                columns={[
                  { title: "Título", name: "name" },
                  { title: "Empreendimento", name: "HOTEL" },
                  { title: "Data", name: "date" },
                  { title: "Ação", getCellValue: (data) => (
                    <Badge
                      color="info"
                      style={{ fontWeight: "normal", cursor: "pointer" }}
                      onClick={() => window.open(data.file, "_blank")}
                    >
                      Ver
                    </Badge>
                  )}
                ]}
              >
                <SearchState />
                <GroupingState grouping={[{ columnName: 'date' }]} />
                <CustomGrouping getChildGroups={this.getChildGroups} />
                <IntegratedFiltering />
                <Table messages={{noData: "Sem informação", }}/>
                <TableHeaderRow />
                <TableGroupRow />
                <Toolbar />
                <SearchPanel messages={{searchPlaceholder: "Procurar"}} />
              </Grid>
            )
          }
          </CardBody>
      </Card>
    );
  }
}

export default CardTable;

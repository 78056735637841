import React, { useState } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  ButtonGroup,
  Button
} from "reactstrap";
import classNames from "classnames";
import { Line } from "react-chartjs-2";

import chartOptions from "variables/charts.jsx";

export const Ocupacao = "ocupacao";
export const DiariaMedia = "diariaMedia";
export const RevPar = "revpar";

const LabelButton = ({ onClick }) => {
  return (
    <div
      style={{ flex: 1, cursor: "pointer" /* , border: "1px solid  #f51" */ }}
      onClick={onClick}
    />
  );
};

const labels = [
  "Jan",
  "Fev",
  "Mar",
  "Abr",
  "Mai",
  "Jun",
  "Jul",
  "Ago",
  "Set",
  "Out",
  "Nov",
  "Dez"
];

const CardChart = props => {
  const [type, setType] = useState(Ocupacao);

  const data = {
    ocupacao: canvas => {
      const actual = props.actual ? props.actual.ocupacao : [];
      const previous = props.previous ? props.previous.ocupacao : [];

      let ctx = canvas.getContext("2d");
      let gradientStroke1 = ctx.createLinearGradient(0, 230, 0, 50);
      let gradientStroke2 = ctx.createLinearGradient(0, 230, 0, 50);

      gradientStroke1.addColorStop(1, "rgba(254, 34, 101,0.2)");
      gradientStroke1.addColorStop(0.4, "rgba(254, 34, 101,0.0)");
      gradientStroke1.addColorStop(0, "rgba(254, 34, 101,0)");

      gradientStroke2.addColorStop(1, "rgba(29,140,248,0.2)");
      gradientStroke2.addColorStop(0.4, "rgba(29,140,248,0.0)");
      gradientStroke2.addColorStop(0, "rgba(29,140,248,0)");

      return {
        labels,
        datasets: [
          {
            fill: true,
            backgroundColor: gradientStroke1,
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBorderColor: "rgba(255,255,255,0)",
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            borderColor: "#FE2265",
            pointBackgroundColor: "#FE2265",
            pointHoverBackgroundColor: "#FE2265",
            label: props.year,
            data: actual
          },
          {
            fill: true,
            backgroundColor: gradientStroke2,
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBorderColor: "rgba(255,255,255,0)",
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            borderColor: "#1f8ef1",
            pointBackgroundColor: "#1f8ef1",
            pointHoverBackgroundColor: "#1f8ef1",
            label: props.year - 1,
            data: previous
          }
        ]
      };
    },
    diariaMedia: canvas => {
      const actual = props.actual ? props.actual.diariaMedia : [];
      const previous = props.previous ? props.previous.diariaMedia : [];

      let ctx = canvas.getContext("2d");
      let gradientStroke1 = ctx.createLinearGradient(0, 230, 0, 50);
      let gradientStroke2 = ctx.createLinearGradient(0, 230, 0, 50);

      gradientStroke1.addColorStop(1, "rgba(254, 34, 101,0.2)");
      gradientStroke1.addColorStop(0.4, "rgba(254, 34, 101,0.0)");
      gradientStroke1.addColorStop(0, "rgba(254, 34, 101,0)");

      gradientStroke2.addColorStop(1, "rgba(29,140,248,0.2)");
      gradientStroke2.addColorStop(0.4, "rgba(29,140,248,0.0)");
      gradientStroke2.addColorStop(0, "rgba(29,140,248,0)");

      return {
        labels,
        datasets: [
          {
            fill: true,
            backgroundColor: gradientStroke1,
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBorderColor: "rgba(255,255,255,0)",
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            borderColor: "#FE2265",
            pointBackgroundColor: "#FE2265",
            pointHoverBackgroundColor: "#FE2265",
            label: props.year,
            data: actual
          },
          {
            fill: true,
            backgroundColor: gradientStroke2,
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBorderColor: "rgba(255,255,255,0)",
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            borderColor: "#1f8ef1",
            pointBackgroundColor: "#1f8ef1",
            pointHoverBackgroundColor: "#1f8ef1",
            label: props.year - 1,
            data: previous
          }
        ]
      };
    },
    revpar: canvas => {
      const actual = props.actual ? props.actual.revpar : [];
      const previous = props.previous ? props.previous.revpar : [];

      let ctx = canvas.getContext("2d");
      let gradientStroke1 = ctx.createLinearGradient(0, 230, 0, 50);
      let gradientStroke2 = ctx.createLinearGradient(0, 230, 0, 50);

      gradientStroke1.addColorStop(1, "rgba(254, 34, 101,0.2)");
      gradientStroke1.addColorStop(0.4, "rgba(254, 34, 101,0.0)");
      gradientStroke1.addColorStop(0, "rgba(254, 34, 101,0)");

      gradientStroke2.addColorStop(1, "rgba(29,140,248,0.2)");
      gradientStroke2.addColorStop(0.4, "rgba(29,140,248,0.0)");
      gradientStroke2.addColorStop(0, "rgba(29,140,248,0)");

      return {
        labels,
        datasets: [
          {
            fill: true,
            backgroundColor: gradientStroke1,
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBorderColor: "rgba(255,255,255,0)",
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            borderColor: "#FE2265",
            pointBackgroundColor: "#FE2265",
            pointHoverBackgroundColor: "#FE2265",
            label: props.year,

            data: actual
          },
          {
            fill: true,
            backgroundColor: gradientStroke2,
            borderColor: "#1f8ef1",
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: "#1f8ef1",
            pointBorderColor: "rgba(255,255,255,0)",
            pointHoverBackgroundColor: "#1f8ef1",
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            label: props.year - 1,
            data: previous
          }
        ]
      };
    }
  };

  return (
    <Card className="card-chart">
      <CardHeader>
        <Row>
          <Col className="text-left" sm="12">
            <ButtonGroup
              className="btn-group-toggle float-right"
              data-toggle="buttons"
            >
              <Button
                tag="label"
                color="info"
                id="0"
                size="sm"
                className={classNames("btn-simple", {
                  active: type === Ocupacao
                })}
                onClick={() => setType(Ocupacao)}
              >
                <input
                  defaultChecked
                  className="d-none"
                  name="options"
                  type="radio"
                />
                <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                  Ocupação
                </span>
                <span className="d-block d-sm-none">
                  <i className="tim-icons icon-single-02" />
                </span>
              </Button>
              <Button
                tag="label"
                color="info"
                id="0"
                size="sm"
                className={classNames("btn-simple", {
                  active: type === DiariaMedia
                })}
                onClick={() => setType(DiariaMedia)}
              >
                <input
                  defaultChecked
                  className="d-none"
                  name="options"
                  type="radio"
                />
                <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                  Diária média
                </span>
                <span className="d-block d-sm-none">
                  <i className="tim-icons icon-single-02" />
                </span>
              </Button>
              <Button
                tag="label"
                color="info"
                id="0"
                size="sm"
                className={classNames("btn-simple", {
                  active: type === RevPar
                })}
                onClick={() => setType(RevPar)}
              >
                <input
                  defaultChecked
                  className="d-none"
                  name="options"
                  type="radio"
                />
                <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                  RevPar
                </span>
                <span className="d-block d-sm-none">
                  <i className="tim-icons icon-single-02" />
                </span>
              </Button>
            </ButtonGroup>
          </Col>
        </Row>
      </CardHeader>
      <CardBody style={{ height: 290 }}>
        <div className="chart-area">
          {type === Ocupacao && (
            <Line
              data={data.ocupacao}
              options={chartOptions("%")}
              getElementAtEvent={a => {}}
            />
          )}
          {type === DiariaMedia && (
            <Line data={data.diariaMedia} options={chartOptions()} />
          )}
          {type === RevPar && (
            <Line data={data.revpar} options={chartOptions()} />
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: 20
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginRight: 20
              }}
            >
              <div
                style={{
                  width: 50,
                  height: 10,
                  borderRadius: 10,
                  backgroundColor: "#1f8ef1",
                  marginRight: 10
                }}
              />
              <span style={{color: "#f4f4f4"}}>{props.year - 1}</span>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div
                style={{
                  width: 50,
                  height: 10,
                  borderRadius: 10,
                  backgroundColor: "#FE2265",
                  marginRight: 10
                }}
              />
              <span style={{color: "#f4f4f4"}}>{props.year}</span>
            </div>
          </div>
          <div
            style={{
              position: "absolute",
              width: "100%",
              height: 40,
              bottom: 50,
              display: "flex",
              flex: 1,
              paddingLeft: 30
            }}
          >
            {labels.map((a, i) => (
              <LabelButton
                onClick={() => {
                  props.onClickLabel(i);
                }}
              />
            ))}
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default CardChart;

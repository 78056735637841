import React, { useRef, useEffect } from "react";
import { Card, CardBody } from "reactstrap";
import CurrencyFormatter from "currency-formatter";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const Skel = () => (
  <SkeletonTheme color="#272A3C" highlightColor="#46495B">
    <Skeleton width={100} height={20} />
  </SkeletonTheme>
)

const CardExpenses = props => {
  const found = CurrencyFormatter.format(props.found, { code: "BRL" });
  const irrf = CurrencyFormatter.format(props.irrf, { code: "BRL" });
  const cardEl = useRef(null);  

  useEffect(() => {
    console.log("refFunction =>", props.refFunction, cardEl)
    if (props.refFunction) {
      props.refFunction(cardEl.current);
    }
  }, [cardEl]);

  return (
    <div ref={cardEl}>
      <Card className="card-chart">
        <CardBody>
          <div
            className="chart-area"
            style={{
              display: "flex",
              justifyContent: "flex-end",
              height: "fit-content",
              flexDirection: "column",
              color: "#f5f7fa",
              padding: "0 15px"
            }}
          >
            <span style={{ fontSize: 24, fontWeight: 100 }}>
              Fundo de reserva{" "}
            </span>
            <span style={{ fontSize: 20 }}>{props.loading ? <Skel /> : found }</span>
            <span style={{ fontSize: 24, fontWeight: 100 }}>IRRF </span>
            <span style={{ fontSize: 20 }}>{props.loading ? <Skel /> : irrf}</span>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default CardExpenses;

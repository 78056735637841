import React, { useState, useEffect } from 'react';
import { Line } from "react-chartjs-2";
import { Row, Col, Card, CardBody } from 'reactstrap';

import chartOptions from "variables/charts.jsx";
import Api from "../../api";
import { connect } from 'react-redux';

import { showMessage } from "../../actions";

const CardChart = ({numdoc, mes, showMessage}) => {
    const [actual, setActual] = useState([]);
    const [previous, setPrevious] = useState([]);
    const [previous2, setPrevious2] = useState([]);
    const [actualYear, setYear] = useState(0);

    const getFirstMonth = (set) => {
        let month = 1;
    
        for (const mes of [...set].sort((a, b) => a-b)) {
            if (mes === month) {
                month++;
                continue;
            }
    
            return month;
        }
    }

    const fillBlankMonths = (arr, isActual, mes) => {
        const set = new Set();

        const arr2 = Array.from({ length: isActual ? mes : 12 }, (_, k) => arr[k]);

        if (isActual) console.log(arr2)

        return arr2.map(data => data || {}).sort((a, b) => {
            if (!a.mes) return 1;
            if (!b.mes) return 1;
        
            return a.mes - b.mes;
        }).map((data) => {
            if (!data.MES) {
                const month = getFirstMonth(set);
        
                set.add(month);
          
                return {
                    RENDALIQUIDA: 0,
                    MES: month
                }
            }
        
            set.add(data.MES);
            
            return data
        })
    }

    useEffect(() => {
        Api.get("investidor/app/grafico", {
            params: {
                NUMDOCUMENTO: numdoc
            }
        }).then(({data}) => {
            if (data.status === "error") {
                showMessage({message: "Erro não esperado", open: true});
            } else {
                let year = Math.max(...data.data.map(item => item.ANO));
                console.log(year)
                let mes = Math.max(...data.data.filter(item => parseInt(item.ANO) === parseInt(year)).map(item => item.MES));
                console.log(mes)

                setPrevious2(fillBlankMonths(data.data.filter(item => parseInt(item.ANO, 10) === year - 2), false).map(item => item.RENDALIQUIDA).reverse());
                setPrevious(fillBlankMonths(data.data.filter(item => parseInt(item.ANO, 10) === year - 1), false).map(item => item.RENDALIQUIDA).reverse());
                setActual(fillBlankMonths(data.data.filter(item => parseInt(item.ANO, 10) === parseInt(year, 10)), true, mes).map(item => item.RENDALIQUIDA).reverse());
                setYear(year);
            }
        })
    }, []);

    const returnDataset = (dataset, color, gradient, year) => {
        if (dataset.length === 0) {
            return false;
        }

        return {
            label: year,
            fill: true,
            backgroundColor: gradient,
            borderColor: color,
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: color,
            pointBorderColor: "rgba(255,255,255,0)",
            pointHoverBackgroundColor: color,
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: dataset
        }   
    }

    return <Row>
            <Col lg="12">
                <Card className="card-chart" style={{ height: 290 }}>
                    <CardBody>
                    <div className="chart-area">
                        <Line
                            data={canvas => {
                                let ctx = canvas.getContext("2d");
                                let gradientStroke1 = ctx.createLinearGradient(0, 230, 0, 50);
                                let gradientStroke2 = ctx.createLinearGradient(0, 230, 0, 50);
                                let gradientStroke3 = ctx.createLinearGradient(0, 230, 0, 50);

                                gradientStroke1.addColorStop(1, "rgba(254, 34, 101,0.2)");
                                gradientStroke1.addColorStop(0.4, "rgba(254, 34, 101,0.0)");
                                gradientStroke1.addColorStop(0, "rgba(254, 34, 101,0)");

                                gradientStroke2.addColorStop(1, "rgba(29,140,248,0.2)");
                                gradientStroke2.addColorStop(0.4, "rgba(29,140,248,0.0)");
                                gradientStroke2.addColorStop(0, "rgba(29,140,248,0)");

                                gradientStroke3.addColorStop(1, "rgba(255, 170, 21,0.2)");
                                gradientStroke3.addColorStop(0.4, "rgba(255, 170, 21,0.0)");
                                gradientStroke3.addColorStop(0, "rgba(255, 170, 21,0)");

                                let actualDataset = returnDataset(actual, "#FE2265", gradientStroke1, actualYear);
                                let previousDataset = returnDataset(previous, "#1f8ef1", gradientStroke2, actualYear - 1);
                                let previous2Dataset = returnDataset(previous2, "#FFAA15", gradientStroke3, actualYear - 2);
                                let datasets = [];

                                if (actualDataset) {
                                    datasets = [actualDataset];
                                }

                                if (previousDataset) {
                                    datasets = [...datasets, previousDataset];
                                }

                                if (previousDataset) {
                                    datasets = [...datasets, previous2Dataset];
                                }

                                return {
                                    labels: [
                                        "Jan",
                                        "Fev",
                                        "Mar",
                                        "Abr",
                                        "Mai",
                                        "Jun",
                                        "Jul",
                                        "Ago",
                                        "Set",
                                        "Out",
                                        "Nov",
                                        "Dez"
                                    ],
                                    datasets
                                };
                            }}
                            options={chartOptions()}
                        />
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                marginTop: 20
                            }}
                        >
                            <div style={{marginRight: 10}}>
                                {
                                    actual.length > 0 && 
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <div
                                            style={{
                                            width: 50,
                                            height: 10,
                                            borderRadius: 10,
                                            backgroundColor: "#FE2265",
                                            marginRight: 10
                                            }}
                                        />
                                        <span style={{color: "#f4f4f4"}}>{actualYear}</span>
                                    </div>
                                }
                            </div>
                            {
                                previous.length > 0 && 
                                <div style={{marginRight: 10}}>
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <div
                                            style={{
                                                width: 50,
                                                height: 10,
                                                borderRadius: 10,
                                                backgroundColor: "#1f8ef1",
                                                marginRight: 10
                                            }}
                                        />
                                        <span style={{color: "#f4f4f4"}}>{actualYear - 1}</span>
                                    </div>
                                </div>
                            }
                            {
                                previous2.length > 0 && 
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <div
                                            style={{
                                                width: 50,
                                                height: 10,
                                                borderRadius: 10,
                                                backgroundColor: "#FFAA15",
                                                marginRight: 10
                                            }}
                                        />
                                        <span style={{color: "#f4f4f4"}}>{actualYear - 2}</span>
                                    </div>
                            }
                        </div>
                    </div>
                </CardBody>
            </Card>
        </Col>
    </Row>
};

export default connect(null, { showMessage })(CardChart);

import {
  reduceSumYear,
  returnPeriods,
  returnDataset,
  mergeHotelValues,
  mergePeriodInfoValues,
  getVariantBetweenValue,
  setAverage
} from "./functions";

export const MONEY = 1;
export const PERCENT = 2;

const data = {
  hoteis: [
    {
      nome: "Ibis Styles São Paulo",
      valores: {
        2018: [
          {
            aluguel: 7674,
            fundoReserva: -4477,
            IRRF: -7822,
            unidades: 2,
            rendaLiquida: 6256,
            ocupacao: 5190,
            diariaMedia: 8208,
            revpar: 3120,
            receitaBruta: 8569,
            resultadoOperacional: 3867,
            margemResultadoOperacional: 5892,
            aluguelUnidade: 8910
          },
          {
            aluguel: 4244,
            fundoReserva: -8264,
            IRRF: -2480,
            unidades: 2,
            rendaLiquida: 9312,
            ocupacao: 130,
            diariaMedia: 8573,
            revpar: 8652,
            receitaBruta: 6409,
            resultadoOperacional: 9258,
            margemResultadoOperacional: 5073,
            aluguelUnidade: 1673
          },
          {
            aluguel: 7943,
            fundoReserva: -155,
            IRRF: -4375,
            unidades: 2,
            rendaLiquida: 7373,
            ocupacao: 518,
            diariaMedia: 1153,
            revpar: 6372,
            receitaBruta: 3839,
            resultadoOperacional: 8440,
            margemResultadoOperacional: 5743,
            aluguelUnidade: 1717
          },
          {
            aluguel: 679,
            fundoReserva: -6435,
            IRRF: -3271,
            unidades: 2,
            rendaLiquida: 8471,
            ocupacao: 990,
            diariaMedia: 983,
            revpar: 324,
            receitaBruta: 6886,
            resultadoOperacional: 6446,
            margemResultadoOperacional: 8680,
            aluguelUnidade: 5728
          },
          {
            aluguel: 679,
            fundoReserva: -6435,
            IRRF: -3271,
            unidades: 2,
            rendaLiquida: 8471,
            ocupacao: 990,
            diariaMedia: 983,
            revpar: 324,
            receitaBruta: 6886,
            resultadoOperacional: 6446,
            margemResultadoOperacional: 8680,
            aluguelUnidade: 5728
          },
          {
            aluguel: 1690,
            fundoReserva: -4030,
            IRRF: -2535,
            unidades: 2,
            rendaLiquida: 2124,
            ocupacao: 5526,
            diariaMedia: 6413,
            revpar: 5169,
            receitaBruta: 4467,
            resultadoOperacional: 1484,
            margemResultadoOperacional: 7881,
            aluguelUnidade: 6680
          },
          {
            aluguel: 7112,
            fundoReserva: -7025,
            IRRF: -3861,
            unidades: 2,
            rendaLiquida: 199,
            ocupacao: 6532,
            diariaMedia: 9827,
            revpar: 1576,
            receitaBruta: 304,
            resultadoOperacional: 4736,
            margemResultadoOperacional: 6216,
            aluguelUnidade: 6889
          },
          {
            aluguel: 2599,
            fundoReserva: -5212,
            IRRF: -6559,
            unidades: 2,
            rendaLiquida: 1362,
            ocupacao: 8216,
            diariaMedia: 6216,
            revpar: 9034,
            receitaBruta: 5911,
            resultadoOperacional: 6839,
            margemResultadoOperacional: 6830,
            aluguelUnidade: 1333
          },
          {
            aluguel: 6922,
            fundoReserva: -9801,
            IRRF: -1081,
            unidades: 2,
            rendaLiquida: 1097,
            ocupacao: 7970,
            diariaMedia: 7739,
            revpar: 9322,
            receitaBruta: 8666,
            resultadoOperacional: 634,
            margemResultadoOperacional: 8930,
            aluguelUnidade: 5472
          },
          {
            aluguel: 9485,
            fundoReserva: -4258,
            IRRF: -7899,
            unidades: 2,
            rendaLiquida: 3218,
            ocupacao: 6953,
            diariaMedia: 9848,
            revpar: 9226,
            receitaBruta: 2999,
            resultadoOperacional: 4463,
            margemResultadoOperacional: 7690,
            aluguelUnidade: 8008
          },
          {
            aluguel: 2772,
            fundoReserva: -8328,
            IRRF: -176,
            unidades: 2,
            rendaLiquida: 9324,
            ocupacao: 1261,
            diariaMedia: 3761,
            revpar: 1103,
            receitaBruta: 4339,
            resultadoOperacional: 6609,
            margemResultadoOperacional: 8288,
            aluguelUnidade: 6450
          },
          {
            aluguel: 8299,
            fundoReserva: -1916,
            IRRF: -1254,
            unidades: 2,
            rendaLiquida: 5483,
            ocupacao: 6581,
            diariaMedia: 9243,
            revpar: 5898,
            receitaBruta: 9433,
            resultadoOperacional: 9692,
            margemResultadoOperacional: 245,
            aluguelUnidade: 5672
          }
        ],
        2019: [
          {
            aluguel: 9773,
            fundoReserva: -8998,
            IRRF: -9852,
            unidades: 2,
            rendaLiquida: 7982,
            ocupacao: 1565,
            diariaMedia: 3926,
            revpar: 944,
            receitaBruta: 7759,
            resultadoOperacional: 5289,
            margemResultadoOperacional: 3539,
            aluguelUnidade: 5965
          },
          {
            aluguel: 7559,
            fundoReserva: -1175,
            IRRF: -1439,
            unidades: 2,
            rendaLiquida: 9699,
            ocupacao: 5341,
            diariaMedia: 292,
            revpar: 2059,
            receitaBruta: 3234,
            resultadoOperacional: 7709,
            margemResultadoOperacional: 4116,
            aluguelUnidade: 5876
          },
          {
            aluguel: 1866,
            fundoReserva: -9192,
            IRRF: -5287,
            unidades: 2,
            rendaLiquida: 565,
            ocupacao: 6032,
            diariaMedia: 2510,
            revpar: 7130,
            receitaBruta: 7092,
            resultadoOperacional: 8379,
            margemResultadoOperacional: 6242,
            aluguelUnidade: 5479
          },
          {
            aluguel: 3002,
            fundoReserva: -3362,
            IRRF: -9225,
            unidades: 2,
            rendaLiquida: 5399,
            ocupacao: 7971,
            diariaMedia: 405,
            revpar: 8593,
            receitaBruta: 4475,
            resultadoOperacional: 3602,
            margemResultadoOperacional: 5779,
            aluguelUnidade: 8771
          },
          {
            aluguel: 5131,
            fundoReserva: -3455,
            IRRF: -7540,
            unidades: 2,
            rendaLiquida: 1834,
            ocupacao: 4627,
            diariaMedia: 3662,
            revpar: 5489,
            receitaBruta: 9297,
            resultadoOperacional: 1011,
            margemResultadoOperacional: 4177,
            aluguelUnidade: 651
          },
          {
            aluguel: 5682,
            fundoReserva: -6808,
            IRRF: -2452,
            unidades: 2,
            rendaLiquida: 187,
            ocupacao: 6952,
            diariaMedia: 987,
            revpar: 1617,
            receitaBruta: 3127,
            resultadoOperacional: 1700,
            margemResultadoOperacional: 5534,
            aluguelUnidade: 7120
          },
          {
            aluguel: 8635,
            fundoReserva: -133,
            IRRF: -4105,
            unidades: 2,
            rendaLiquida: 6214,
            ocupacao: 3938,
            diariaMedia: 609,
            revpar: 6614,
            receitaBruta: 4404,
            resultadoOperacional: 6584,
            margemResultadoOperacional: 8321,
            aluguelUnidade: 7881
          },
          {
            aluguel: 4660,
            fundoReserva: -8677,
            IRRF: -8427,
            unidades: 2,
            rendaLiquida: 5286,
            ocupacao: 1120,
            diariaMedia: 485,
            revpar: 2543,
            receitaBruta: 2780,
            resultadoOperacional: 1280,
            margemResultadoOperacional: 4431,
            aluguelUnidade: 5177
          },
          {
            aluguel: 3031,
            fundoReserva: -425,
            IRRF: -8591,
            unidades: 2,
            rendaLiquida: 4866,
            ocupacao: 2206,
            diariaMedia: 9900,
            revpar: 5437,
            receitaBruta: 3083,
            resultadoOperacional: 9781,
            margemResultadoOperacional: 8476,
            aluguelUnidade: 7453
          },
          {
            aluguel: 7331,
            fundoReserva: -2456,
            IRRF: -7936,
            unidades: 2,
            rendaLiquida: 3044,
            ocupacao: 3011,
            diariaMedia: 6471,
            revpar: 1902,
            receitaBruta: 8470,
            resultadoOperacional: 8581,
            margemResultadoOperacional: 5896,
            aluguelUnidade: 9910
          },
          {
            aluguel: 6911,
            fundoReserva: -2820,
            IRRF: -4811,
            unidades: 2,
            rendaLiquida: 9639,
            ocupacao: 2804,
            diariaMedia: 6339,
            revpar: 1582,
            receitaBruta: 6065,
            resultadoOperacional: 2770,
            margemResultadoOperacional: 2796,
            aluguelUnidade: 1767
          },
          {
            aluguel: 2772,
            fundoReserva: -330,
            IRRF: -5408,
            unidades: 2,
            rendaLiquida: 2676,
            ocupacao: 4719,
            diariaMedia: 582,
            revpar: 9224,
            receitaBruta: 3210,
            resultadoOperacional: 5193,
            margemResultadoOperacional: 1290,
            aluguelUnidade: 6727
          }
        ]
      }
    },
    {
      nome: "Ibis Styles Porto Alegre",
      valores: {
        2018: [
          {
            aluguel: 130,
            fundoReserva: -8999,
            IRRF: -7146,
            unidades: 2,
            rendaLiquida: 1397,
            ocupacao: 227,
            diariaMedia: 1446,
            revpar: 3701,
            receitaBruta: 2421,
            resultadoOperacional: 8203,
            margemResultadoOperacional: 2917,
            aluguelUnidade: 7491
          },
          {
            aluguel: 7408,
            fundoReserva: -248,
            IRRF: -3046,
            unidades: 2,
            rendaLiquida: 8026,
            ocupacao: 6311,
            diariaMedia: 1182,
            revpar: 2144,
            receitaBruta: 4624,
            resultadoOperacional: 3819,
            margemResultadoOperacional: 5110,
            aluguelUnidade: 9663
          },
          {
            aluguel: 3500,
            fundoReserva: -2006,
            IRRF: -1181,
            unidades: 2,
            rendaLiquida: 5748,
            ocupacao: 6485,
            diariaMedia: 6290,
            revpar: 4466,
            receitaBruta: 2884,
            resultadoOperacional: 5348,
            margemResultadoOperacional: 3500,
            aluguelUnidade: 135
          },
          {
            aluguel: 3784,
            fundoReserva: -8213,
            IRRF: -1218,
            unidades: 2,
            rendaLiquida: 5577,
            ocupacao: 9300,
            diariaMedia: 2211,
            revpar: 6852,
            receitaBruta: 3590,
            resultadoOperacional: 5116,
            margemResultadoOperacional: 2824,
            aluguelUnidade: 7553
          },
          {
            aluguel: 9291,
            fundoReserva: -9036,
            IRRF: -6281,
            unidades: 2,
            rendaLiquida: 6146,
            ocupacao: 2308,
            diariaMedia: 7417,
            revpar: 9745,
            receitaBruta: 3750,
            resultadoOperacional: 7930,
            margemResultadoOperacional: 1701,
            aluguelUnidade: 7624
          },
          {
            aluguel: 8076,
            fundoReserva: -4838,
            IRRF: -7249,
            unidades: 2,
            rendaLiquida: 3939,
            ocupacao: 9728,
            diariaMedia: 153,
            revpar: 1360,
            receitaBruta: 254,
            resultadoOperacional: 7648,
            margemResultadoOperacional: 9033,
            aluguelUnidade: 1021
          },
          {
            aluguel: 7823,
            fundoReserva: -166,
            IRRF: -7538,
            unidades: 2,
            rendaLiquida: 8352,
            ocupacao: 5705,
            diariaMedia: 7729,
            revpar: 5545,
            receitaBruta: 2817,
            resultadoOperacional: 1495,
            margemResultadoOperacional: 6441,
            aluguelUnidade: 8910
          },
          {
            aluguel: 7973,
            fundoReserva: -8578,
            IRRF: -226,
            unidades: 2,
            rendaLiquida: 2656,
            ocupacao: 3492,
            diariaMedia: 783,
            revpar: 5042,
            receitaBruta: 1120,
            resultadoOperacional: 3179,
            margemResultadoOperacional: 1775,
            aluguelUnidade: 4086
          },
          {
            aluguel: 8680,
            fundoReserva: -5808,
            IRRF: -5971,
            unidades: 2,
            rendaLiquida: 3849,
            ocupacao: 4567,
            diariaMedia: 8994,
            revpar: 6373,
            receitaBruta: 5884,
            resultadoOperacional: 9717,
            margemResultadoOperacional: 6994,
            aluguelUnidade: 3323
          },
          {
            aluguel: 440,
            fundoReserva: -1669,
            IRRF: -9989,
            unidades: 2,
            rendaLiquida: 946,
            ocupacao: 5572,
            diariaMedia: 9805,
            revpar: 3639,
            receitaBruta: 3298,
            resultadoOperacional: 8928,
            margemResultadoOperacional: 6141,
            aluguelUnidade: 4456
          },
          {
            aluguel: 5522,
            fundoReserva: -1880,
            IRRF: -4498,
            unidades: 2,
            rendaLiquida: 9541,
            ocupacao: 1747,
            diariaMedia: 4189,
            revpar: 1872,
            receitaBruta: 7689,
            resultadoOperacional: 8362,
            margemResultadoOperacional: 3932,
            aluguelUnidade: 5920
          },
          {
            aluguel: 6343,
            fundoReserva: -5334,
            IRRF: -6023,
            unidades: 2,
            rendaLiquida: 1986,
            ocupacao: 7881,
            diariaMedia: 9547,
            revpar: 4126,
            receitaBruta: 6402,
            resultadoOperacional: 2730,
            margemResultadoOperacional: 3543,
            aluguelUnidade: 8767
          }
        ],
        2019: [
          {
            aluguel: 3606,
            fundoReserva: -8377,
            IRRF: -1385,
            unidades: 2,
            rendaLiquida: 2895,
            ocupacao: 4550,
            diariaMedia: 6739,
            revpar: 2953,
            receitaBruta: 6273,
            resultadoOperacional: 9076,
            margemResultadoOperacional: 5942,
            aluguelUnidade: 9976
          },
          {
            aluguel: 3495,
            fundoReserva: -7876,
            IRRF: -1903,
            unidades: 2,
            rendaLiquida: 8665,
            ocupacao: 7032,
            diariaMedia: 3510,
            revpar: 8834,
            receitaBruta: 4116,
            resultadoOperacional: 4204,
            margemResultadoOperacional: 5202,
            aluguelUnidade: 1745
          },
          {
            aluguel: 4745,
            fundoReserva: -8825,
            IRRF: -2373,
            unidades: 2,
            rendaLiquida: 5361,
            ocupacao: 5633,
            diariaMedia: 2070,
            revpar: 5778,
            receitaBruta: 9797,
            resultadoOperacional: 8311,
            margemResultadoOperacional: 8186,
            aluguelUnidade: 2191
          },
          {
            aluguel: 900,
            fundoReserva: -3417,
            IRRF: -7835,
            unidades: 2,
            rendaLiquida: 9978,
            ocupacao: 1797,
            diariaMedia: 4895,
            revpar: 1228,
            receitaBruta: 3540,
            resultadoOperacional: 2790,
            margemResultadoOperacional: 250,
            aluguelUnidade: 4696
          },
          {
            aluguel: 7327,
            fundoReserva: -7676,
            IRRF: -8189,
            unidades: 2,
            rendaLiquida: 4192,
            ocupacao: 3446,
            diariaMedia: 7454,
            revpar: 1594,
            receitaBruta: 8622,
            resultadoOperacional: 8264,
            margemResultadoOperacional: 117,
            aluguelUnidade: 1419
          },
          {
            aluguel: 4926,
            fundoReserva: -4908,
            IRRF: -9053,
            unidades: 2,
            rendaLiquida: 1838,
            ocupacao: 5376,
            diariaMedia: 7980,
            revpar: 8027,
            receitaBruta: 1055,
            resultadoOperacional: 7051,
            margemResultadoOperacional: 734,
            aluguelUnidade: 3928
          },
          {
            aluguel: 6193,
            fundoReserva: -2748,
            IRRF: -5388,
            unidades: 2,
            rendaLiquida: 722,
            ocupacao: 9223,
            diariaMedia: 8274,
            revpar: 4473,
            receitaBruta: 9438,
            resultadoOperacional: 1942,
            margemResultadoOperacional: 3509,
            aluguelUnidade: 5364
          },
          {
            aluguel: 9535,
            fundoReserva: -3443,
            IRRF: -785,
            unidades: 2,
            rendaLiquida: 9527,
            ocupacao: 1104,
            diariaMedia: 3860,
            revpar: 4667,
            receitaBruta: 4745,
            resultadoOperacional: 8608,
            margemResultadoOperacional: 2991,
            aluguelUnidade: 5526
          },
          {
            aluguel: 435,
            fundoReserva: -4185,
            IRRF: -6565,
            unidades: 2,
            rendaLiquida: 3039,
            ocupacao: 6798,
            diariaMedia: 6818,
            revpar: 9722,
            receitaBruta: 4653,
            resultadoOperacional: 2254,
            margemResultadoOperacional: 1121,
            aluguelUnidade: 4369
          },
          {
            aluguel: 1625,
            fundoReserva: -7924,
            IRRF: -3676,
            unidades: 2,
            rendaLiquida: 1428,
            ocupacao: 5095,
            diariaMedia: 3844,
            revpar: 6611,
            receitaBruta: 8178,
            resultadoOperacional: 5784,
            margemResultadoOperacional: 5387,
            aluguelUnidade: 3075
          },
          {
            aluguel: 219,
            fundoReserva: -6819,
            IRRF: -5634,
            unidades: 2,
            rendaLiquida: 2031,
            ocupacao: 4616,
            diariaMedia: 3948,
            revpar: 9185,
            receitaBruta: 6721,
            resultadoOperacional: 9735,
            margemResultadoOperacional: 7144,
            aluguelUnidade: 9179
          },
          {
            aluguel: 4583,
            fundoReserva: -5569,
            IRRF: -2044,
            unidades: 2,
            rendaLiquida: 853,
            ocupacao: 8271,
            diariaMedia: 9747,
            revpar: 649,
            receitaBruta: 9073,
            resultadoOperacional: 5222,
            margemResultadoOperacional: 6523,
            aluguelUnidade: 887
          }
        ]
      }
    }
  ],
  mensagens: [
    {
      1574278740157: {
        titulo: "Temos novidade na rede Ibis",
        mensagem:
          "Lorem Ipsum é simplesmente uma simulação de texto da indústria tipográfica e de impressos, e vem sendo utilizado desde o século XVI, quando um impressor desconhecido pegou uma bandeja de tipos e os embaralhou para fazer um livro de modelos de tipos. Lorem Ipsum sobreviveu não só a cinco séculos.",
        data: 1574278740157
      },
      1574278740158: {
        titulo: "Temos novidade na rede Ibis",
        mensagem:
          "Lorem Ipsum é simplesmente uma simulação de texto da indústria tipográfica e de impressos, e vem sendo utilizado desde o século XVI, quando um impressor desconhecido pegou uma bandeja de tipos e os embaralhou para fazer um livro de modelos de tipos. Lorem Ipsum sobreviveu não só a cinco séculos.",
        data: 1574278740158
      },
      1574278740159: {
        titulo: "Temos novidade na rede Ibis",
        mensagem:
          "Lorem Ipsum é simplesmente uma simulação de texto da indústria tipográfica e de impressos, e vem sendo utilizado desde o século XVI, quando um impressor desconhecido pegou uma bandeja de tipos e os embaralhou para fazer um livro de modelos de tipos. Lorem Ipsum sobreviveu não só a cinco séculos.",
        data: 1574278740159
      },
      1574278740180: {
        titulo: "Temos novidade na rede Ibis",
        mensagem:
          "Lorem Ipsum é simplesmente uma simulação de texto da indústria tipográfica e de impressos, e vem sendo utilizado desde o século XVI, quando um impressor desconhecido pegou uma bandeja de tipos e os embaralhou para fazer um livro de modelos de tipos. Lorem Ipsum sobreviveu não só a cinco séculos.",
        data: 1574278740180
      },
      1574278740182: {
        titulo: "Temos novidade na rede Ibis",
        mensagem:
          "Lorem Ipsum é simplesmente uma simulação de texto da indústria tipográfica e de impressos, e vem sendo utilizado desde o século XVI, quando um impressor desconhecido pegou uma bandeja de tipos e os embaralhou para fazer um livro de modelos de tipos. Lorem Ipsum sobreviveu não só a cinco séculos.",
        data: 1574278740182
      }
    }
  ]
};

export const periods = [
  "JANEIRO",
  "FEVEREIRO",
  "MARÇO",
  "ABRIL",
  "MAIO",
  "JUNHO",
  "JULHO",
  "AGOSTO",
  "SETEMBRO",
  "OUTUBRO",
  "NOVEMBRO",
  "DEZEMBRO"
];

export const hotelIndex = {
  ocupacao: { title: "Ocupação", unit: PERCENT },
  diariaMedia: { title: "Diária Média R$", unit: MONEY },
  revpar: { title: "RevPar R$", unit: MONEY },
  receitaBruta: { title: "Receita Bruta R$", unit: MONEY },
  resultadoOperacional: { title: "Resultado Operacional R$", unit: MONEY },
  margemResultadoOperacional: {
    title: "Margem Resultado Operacional",
    unit: PERCENT
  },
  aluguelUnidade: { title: "Aluguel por unidade R$", unit: MONEY }
};

export const formatToValue = value => {
  var money = value.toString();
  money = money.replace(/([0-9]{2})$/g, ",$1");

  if (money.length > 6) {
    money = money.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");
  }

  return money;
};

export const Capitalize = str =>
  str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();

export const beautifyDataset = (dataset, color, label) => ({
  data: dataset,
  color: () => color,
  label
});

export const getHomeValues = (yearSelected, monthSelected) => {
  const hotels = data.hoteis;

  let values = {
    actualData: null,
    periods: [],
    actualYear: null,
    previousYear: null
  };

  for (const hotel of hotels) {
    // Percorrer por todos os hotéis
    let state = {
      actualData: null,
      periods: [],
      actualYear: null,
      previousYear: null
    };

    for (const year in hotel.valores) {
      // Percorrer por todos os anos daquele hotel

      if (yearSelected === parseInt(year, 10)) {
        // Buscar pelo ano atual
        const accum = hotel.valores[year].reduce(reduceSumYear); // Somar os valores de todos os meses desse ano
        const dataset = hotel.valores[year].map(returnDataset("rendaLiquida")); // Retorna o dataset do gráfico do ano atual => Renda Líquida

        const periods = returnPeriods(hotel.valores, year); // Organiza os valores dos meses em um array

        state.actualData = periods[monthSelected]; // Pegar o mês selecionado
        state.periods = periods.reverse(); // Inverter a ordem do períodos para exibir o mes atual e decrementar os meses

        state.actualYear = {
          accum,
          chart: dataset,
          year: parseInt(year, 10)
        };
      } else if (yearSelected - 1 === parseInt(year, 10)) {
        // Buscar pelo ano anterior
        const accum = hotel.valores[year].reduce(reduceSumYear); // Somar os valores de todos os meses desse ano
        const dataset = hotel.valores[year].map(returnDataset("rendaLiquida")); // Retorna o dataset do gráfico do ano anterior => Renda Líquida

        state.previousYear = {
          accum,
          chart: dataset,
          year: parseInt(year, 10)
        };
      }
    }

    values = mergeHotelValues(values, state);
  }

  return values;
};

export const getPeriodInfoValues = (yearSelected, monthSelected, accumType) => {
  const hotels = data.hoteis;

  let values = {
    periodData: null,
    hotelList: []
  };

  for (const hotel of hotels) {
    const state = {
      periodData: null,
      hotelList: []
    };

    if (accumType === 1) {
      if (!hotel.valores[yearSelected]) continue;

      state.periodData = hotel.valores[yearSelected].reduce(reduceSumYear);
    } else {
      if (
        !hotel.valores[yearSelected] ||
        !hotel.valores[yearSelected][monthSelected]
      )
        continue;

      const periods = returnPeriods(hotel.valores, yearSelected);

      state.periodData = periods[monthSelected];
    }

    const hotelData = {
      ...state.periodData,
      name: hotel.nome
    };

    state.hotelList = [...values.hotelList, hotelData];

    values = mergePeriodInfoValues(values, state);
  }

  return values;
};

export const hasValues = (year, month, accumType) => {
  const hotels = data.hoteis;

  for (const hotel of hotels) {
    // Percorrer por todos os hotéis
    if (accumType === 1) {
      if (!!hotel.valores[year]) {
        return true;
      }
    } else {
      if (!!hotel.valores[year] && !!hotel.valores[year][month]) {
        return true;
      }
    }
  }

  return false;
};

export const getHotelPeriodData = (index, year, month) => {
  const hotel = data.hoteis[index];

  let values = {
    actual: null,
    previous: null,
    variant: null,
    actualAccum: null,
    previousAccum: null,
    variantAccum: null
  };

  if (!hotel.valores.hasOwnProperty(year) || hotel.valores[year].length === 0) {
    return null;
  }

  if (
    !hotel.valores.hasOwnProperty(year - 1) ||
    hotel.valores[year - 1].length === 0
  ) {
    return null;
  }

  values.actual = hotel.valores[year][month];
  values.previous = hotel.valores[year - 1][month];
  values.actualAccum = hotel.valores[year].reduce(reduceSumYear);
  values.previousAccum = hotel.valores[year - 1].reduce(reduceSumYear);

  values.variant = getVariantBetweenValue(values.actual, values.previous);
  values.variantAccum = getVariantBetweenValue(
    values.actualAccum,
    values.previousAccum
  );

  values = setAverage(values); // Aplicar a média nos campos de porcentagem

  return { ...values, name: hotel.nome };
};

export const getHotelDatasets = (index, year) => {
  const hotel = data.hoteis[index];

  if (!hotel) return null;

  const values = hotel.valores[year];

  if (!values) return null;

  const dataset = {
    ocupacao: values.map(returnDataset("ocupacao")),
    diariaMedia: values.map(returnDataset("diariaMedia")),
    revpar: values.map(returnDataset("revpar"))
  };

  return dataset;
};

export const getNotifications = () => Object.values(data.mensagens[0]);

export default data;
